import React from 'react';

const Footer = () => {
  return (
    <div className="mt-8 py-8 text-center text-gray-500">
      <div className="mx-auto mb-4 h-[1px] w-[100px] bg-gray-400"></div>
      West Wimbledon Bowling Club
    </div>
  );
};
export default Footer;
