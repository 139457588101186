export const LOCAL_STORAGE_KEYS = {
  USER: 'wwbc_user',
  USER_TOKEN: 'wwbc_user_token',
  USER_PASSWORD_CHANGE: 'wwbc_user_password_change',
};

// export const BACKEND_URL = 'http://localhost:3001';
export const BACKEND_URL = 'https://api.wwbowls.club';

export const PATHS = {
  HOME: '/',
  CONTACT: '/contact',
  FIXTURES: '/fixtures',
  GALLERY: '/gallery',
  LOGIN: '/login',
  MEMBERSHIP: '/membership',
  NEWS: '/news',
  FORGOT_PASSWORD: '/forgot-password',

  ADMIN_DASHBOARD: '/admin',
  UPDATE_PASSWORD: '/update-password',
};

export const MESSAGE_OPTIONS = ['Membership Enquiry', 'Venue Hire', 'Other'];

export const NAV_LINKS = [
  { label: 'HOME', url: PATHS.HOME },
  { label: 'News', url: PATHS.NEWS },
  { label: 'Fixtures', url: PATHS.FIXTURES },
  { label: 'Gallery', url: PATHS.GALLERY },
  { label: 'Membership', url: PATHS.MEMBERSHIP },
  { label: 'Contact', url: PATHS.CONTACT },
];

export const PERMISSON_TYPES = {
  POST_CREATE: 'post_create',
  POST_READ: 'post_read',
  POST_UPDATE: 'post_update',
  POST_DELETE: 'post_delete',

  NEWS_CREATE: 'news_create',
  NEWS_READ: 'news_read',
  NEWS_UPDATE: 'news_update',
  NEWS_DELETE: 'news_delete',

  MEMBERS_CREATE: 'members_create',
  MEMBERS_READ: 'members_read',
  MEMBERS_UPDATE: 'members_update',
  MEMBERS_DELETE: 'members_delete',

  CLUB_CREATE: 'club_create',
  CLUB_READ: 'club_read',
  CLUB_UPDATE: 'club_update',
  CLUB_DELETE: 'club_delete',

  GAME_TYPES_CREATE: 'game_types_create',
  GAME_TYPES_READ: 'game_types_read',
  GAME_TYPES_UPDATE: 'game_types_update',
  GAME_TYPES_DELETE: 'game_types_delete',

  FIXTURE_CREATE: 'fixture_create',
  FIXTURE_READ: 'fixture_read',
  FIXTURE_UPDATE: 'fixture_update',
  FIXTURE_DELETE: 'fixture_delete',

  GALLERY_CREATE: 'gallery_create',
  GALLERY_READ: 'gallery_read',
  GALLERY_UPDATE: 'gallery_update',
  GALLERY_DELETE: 'gallery_delete',

  COMPETITION_CREATE: 'competition_create',
  COMPETITION_READ: 'competition_read',
  COMPETITION_UPDATE: 'competition_update',
  COMPETITION_DELETE: 'competition_delete',

  SCORE_UPDATE: 'score_update',

  CLUB_DOCUMENTS_CREATE: 'club_documents_create',
  CLUB_DOCUMENTS_READ: 'club_documents_read',
  CLUB_DOCUMENTS_DELETE: 'club_documents_delete',
};

export const USER_ROLES = [
  {
    label: 'Admin',
    value: 'Admin',
    permissions: Object.values(PERMISSON_TYPES).filter(
      (item) => !item.includes('_delete'),
    ),
  },
  {
    label: 'Committee',
    value: 'Committee',
    permissions: Object.values(PERMISSON_TYPES).filter((item) =>
      item.includes('_read'),
    ),
  },
  {
    label: 'Full Member',
    value: 'PlayingMember',
    permissions: Object.values(PERMISSON_TYPES).filter((item) =>
      item.includes('_read'),
    ),
  },
  {
    label: 'Social Member',
    value: 'SocialMember',
    permissions: Object.values(PERMISSON_TYPES).filter((item) =>
      item.includes('_read'),
    ),
  },
];

export const PERMISSION_MATRIX = [
  [
    'Post',
    PERMISSON_TYPES.POST_READ,
    PERMISSON_TYPES.POST_CREATE,
    PERMISSON_TYPES.POST_UPDATE,
    PERMISSON_TYPES.POST_DELETE,
  ],
  [
    'News',
    PERMISSON_TYPES.NEWS_READ,
    PERMISSON_TYPES.NEWS_CREATE,
    PERMISSON_TYPES.NEWS_UPDATE,
    PERMISSON_TYPES.NEWS_DELETE,
  ],
  [
    'Members (Users)',
    PERMISSON_TYPES.MEMBERS_READ,
    PERMISSON_TYPES.MEMBERS_CREATE,
    PERMISSON_TYPES.MEMBERS_UPDATE,
    PERMISSON_TYPES.MEMBERS_DELETE,
  ],
  [
    'Club',
    PERMISSON_TYPES.CLUB_READ,
    PERMISSON_TYPES.CLUB_CREATE,
    PERMISSON_TYPES.CLUB_UPDATE,
    PERMISSON_TYPES.CLUB_DELETE,
  ],
  [
    'Game Types',
    PERMISSON_TYPES.GAME_TYPES_READ,
    PERMISSON_TYPES.GAME_TYPES_CREATE,
    PERMISSON_TYPES.GAME_TYPES_UPDATE,
    PERMISSON_TYPES.GAME_TYPES_DELETE,
  ],
  [
    'Fixtures',
    PERMISSON_TYPES.FIXTURE_READ,
    PERMISSON_TYPES.FIXTURE_CREATE,
    PERMISSON_TYPES.FIXTURE_UPDATE,
    PERMISSON_TYPES.FIXTURE_DELETE,
  ],
  [
    'Gallery',
    PERMISSON_TYPES.GALLERY_READ,
    PERMISSON_TYPES.GALLERY_CREATE,
    PERMISSON_TYPES.GALLERY_UPDATE,
    PERMISSON_TYPES.GALLERY_DELETE,
  ],
  [
    'Competition',
    PERMISSON_TYPES.COMPETITION_READ,
    PERMISSON_TYPES.COMPETITION_CREATE,
    PERMISSON_TYPES.COMPETITION_UPDATE,
    PERMISSON_TYPES.COMPETITION_DELETE,
  ],
  ['Score', null, null, PERMISSON_TYPES.SCORE_UPDATE, null],
  [
    'Club Documents',
    PERMISSON_TYPES.CLUB_DOCUMENTS_READ,
    PERMISSON_TYPES.CLUB_DOCUMENTS_CREATE,
    null,
    PERMISSON_TYPES.CLUB_DOCUMENTS_DELETE,
  ],
];

export const VENUE_TYPES = [
  { label: 'Home', value: 'Home' },
  { label: 'Away', value: 'Away' },
];

export const DEFAULT_RINK_TYPES = [
  { label: '3 Rinks', value: '3 Rinks' },
  { label: '4 Rinks', value: '4 Rinks' },
  { label: '6 Rinks', value: '4 Rinks' },
  { label: '3 Triples', value: '3 Triples' },
  { label: '4 Triples', value: '4 Triples' },
];

export const getYearOptions = () => {
  const start = 1990;
  const end = 2023;
  let out = [];
  for (let index = start; index < end; index += 1) {
    out.push({
      label: index?.toString(),
      value: index?.toString(),
    });
  }
  return out.reverse();
};

export const QUILL_FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'code',
  'color',
  'background',
  'code-block',
  'align',
];

export const AUDIENCE_OPTIONS = [
  { label: 'Everyone', value: 'Everyone' },
  { label: 'Club Members', value: 'ClubMembers' },
];
