import { AiFillDelete, AiFillEdit, AiFillEye } from 'react-icons/ai';
import {
  DEFAULT_RINK_TYPES,
  PERMISSON_TYPES,
  VENUE_TYPES,
} from '../../helpers/constant';

import APIRequest from '../../helpers/api-request';
import { CSVLink } from 'react-csv';
import CommonModal from '../common/modal';
import CreatableSelect from 'react-select/creatable';
import FixtureScoreEditModal from './fixture-score-edit-modal';
import React from 'react';
import Select from 'react-select';
import Spinner from '../common/spinner';
import UserContext from '../../contexts/user-context/user-context';
import dayjs from 'dayjs';
import { hasPermission } from '../../helpers/functions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const CSV_HEADERS = [
  { label: 'Fixture Date', key: 'date' },
  { label: 'Fixture Time', key: 'time' },
  { label: 'Away Team', key: 'opponent' },
  { label: 'Home Team', key: 'homeTeam' },
  { label: 'Rinks', key: 'rinks' },
  { label: 'Venue', key: 'venue' },
  { label: 'Event Name', key: 'eventName' },
  { label: 'Home Score', key: 'ourScore' },
  { label: 'Away Team Score', key: 'opponentScore' },
  { label: 'Joinable Event', key: 'isJoinable' },
  { label: 'Notes', key: 'notes' },
];

const CSV_DATA = [
  {
    date: '2022-12-17',
    time: '00:00',
    opponent: 'Captain Vs Vice Captain',
    homeTeam: 'WWBC',
    rinks: '3 Rinks',
    venue: 'Home',
    eventName: 'Friendlies',
    isJoinable: 'Yes',
    notes: 'Hello this is a note',
  },
  {
    date: '2023-09-17',
    time: '12:40',
    opponent: 'WWBC',
    homeTeam: 'Test Club Team',
    rinks: 'test rink',
    venue: 'Home',
    eventName: 'WWBC Event',
    isJoinable: 'Yes',
    notes:
      '"Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga dignissimos aut impedit alias? Perferendis eaque beatae sapiente non quo porro velit voluptas itaque exercitationem deleniti magnam voluptatibus praesentium, repellendus vitae?"',
  },
  {
    date: '2023-05-17',
    time: '16:00',
    opponent: 'WWBC',
    homeTeam: 'Southey',
    rinks: '6 Rinks',
    venue: 'Away',
    eventName: 'Kington & District League',
    ourScore: 5,
    opponentScore: 3,
    isJoinable: 'No',
    notes: '',
  },
];

const formatFixtureData = (data, clubsData) => {
  let out = [];
  let possibleYears = [];

  const wwbcClub = clubsData?.find((club) => club?.fullName === 'WWBC');

  for (const item of data) {
    const fixtureObject = {
      id: item?.id,
      fixtureTime: item?.dateTime,
      rinks: item?.rinks,
      homeName: item?.homeTeam?.fullName ?? wwbcClub?.fullName,
      score: item?.ourScore?.length <= 0 ? null : item?.ourScore,
      opponentScore: item?.opponentScore <= 0 ? null : item?.opponentScore,
      opponentName: item?.opponent?.fullName,
      venue: item?.venue,
      eventName: item?.event?.name,
      year: dayjs(item?.dateTime).year(),
      interestedUserIDs: item?.interestedUserIDs,
      interestedUsers: item?.interestedUsers,
      isJoinable: item?.isJoinable,
      notes: item?.notes,
    };
    if (!possibleYears.includes(fixtureObject.year)) {
      possibleYears.push(fixtureObject.year);
    }
    out.push(fixtureObject);
  }

  return {
    out,
    possibleYears,
  };
};

const AdminFixturesSection = () => {
  const { permissions = [] } = React.useContext(UserContext);

  const [filters, setFilters] = React.useState({
    year: dayjs().year(),
    eventName: 'All',
  });
  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
    clubs: [],
    events: [],
  });

  const [open, setOpen] = React.useState(false);
  const [scoreModal, setScoreModal] = React.useState({
    show: false,
    data: {},
  });
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [allowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.FIXTURE_UPDATE, permissions),
  );
  const [allowDelete] = React.useState(
    hasPermission(PERMISSON_TYPES.FIXTURE_DELETE, permissions),
  );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.FIXTURE_CREATE, permissions),
  );
  const [allowFixtureUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.SCORE_UPDATE, permissions),
  );
  const [userInterestModal, setUserInterestModal] = React.useState({
    show: false,
    data: [],
  });
  const [fixtueUpload, setFixtureUpload] = React.useState(false);
  const [file, setFile] = React.useState();
  const [multipleFixtureError, setMultipleFixtureError] = React.useState({
    message: '',
    items: [],
  });

  const { handleSubmit, setValue, reset, getValues, register } = useForm();

  React.useEffect(() => {
    fetchFixtureClubList();
  }, []);

  React.useEffect(() => {
    if (state.name === 'results') {
      calculateDataToShow(filters, state.data);
    }
  }, [filters]);

  React.useEffect(() => {
    if (!fixtueUpload) {
      setMultipleFixtureError({
        message: '',
        items: [],
      });
    }
  }, [fixtueUpload]);

  const fetchFixtureClubList = async () => {
    const results = await Promise.all([
      APIRequest('FIXTURES', 'GET', { encrypt: true }),
      APIRequest('CLUBS', 'GET', { encrypt: true }),
      APIRequest('EVENTS', 'GET', { encrypt: true }),
    ]);
    for (const result of results) {
      if (!result || !result.ok) {
        setState({
          name: 'invalid',
          message: result.message,
        });
        return;
      }
    }

    const { out, possibleYears } = formatFixtureData(
      results[0]?.data,
      results[1]?.data,
    );

    setState({
      name: 'loading',
      data: out,
      possibleYears,
      clubs: results[1]?.data?.map((club) => ({
        label: club?.fullName,
        value: club.id,
      })),
      events: results[2]?.data?.map((event) => ({
        label: event?.name,
        value: event.id,
      })),
    });

    calculateDataToShow(filters, out);
  };

  const calculateDataToShow = (currentFilters, allData) => {
    setState((previous) => ({
      ...previous,
      name: 'loading',
    }));
    const sortedAllData = allData.sort(
      (first, second) =>
        dayjs(first.fixtureTime).unix() - dayjs(second.fixtureTime).unix(),
    );
    let filteredEvents = [];
    let eventsToShow = ['All'];
    let upcomingFixtureFound = false;
    for (const item of sortedAllData) {
      if (item.year === currentFilters.year) {
        if (!eventsToShow.includes(item.eventName)) {
          eventsToShow.push(item.eventName);
        }

        if (currentFilters.eventName !== 'All') {
          if (item.eventName === currentFilters.eventName) {
            let active = upcomingFixtureFound
              ? false
              : dayjs().isBefore(dayjs(item.fixtureTime));
            if (active) {
              upcomingFixtureFound = true;
            }
            filteredEvents.push({ ...item, active });
          }
        } else {
          let active = upcomingFixtureFound
            ? false
            : dayjs().isBefore(dayjs(item.fixtureTime));
          if (active) {
            upcomingFixtureFound = true;
          }
          filteredEvents.push({ ...item, active });
        }
      }
    }

    setState((previous) => ({
      ...previous,
      name: 'results',
      dataToShow: filteredEvents,
      eventsToShow,
    }));
  };

  const handleFixtureCall = async (data) => {
    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }
    let result = isEditMode
      ? await APIRequest('FIXTURES', 'PUT', {
          encrypt: true,
          addToUrl: `/${data.id}`,
          body: {
            ...data,
            dateTime: dayjs(data.dateTime).toDate(),
          },
        })
      : await APIRequest('FIXTURES', 'POST', {
          encrypt: true,
          body: {
            ...data,
            dateTime: dayjs(data.dateTime).toDate(),
            ourScore: data?.score ?? '',
            opponentScore: data?.opponentScore ?? '',
          },
        });

    if (result && result.ok) {
      toast.success('Added new fixture successfully');
      reset();
      setOpen(false);
      fetchFixtureClubList();
    } else {
      toast.warning('Failed to add new fixture');
    }
  };

  const handleDelete = async (id) => {
    if (allowDelete) {
      if (window.confirm('Are you sure you want to delete the fixture?')) {
        const result = await APIRequest('FIXTURES', 'DELETE', {
          encrypt: true,
          addToUrl: `/${id}`,
        });
        if (result && result.ok) {
          toast.success('Deleted fixture successfully');
          fetchFixtureClubList();
        } else {
          toast.warning('Failed to delete fixture');
        }
      }
    }
  };

  const openModal = (data, isEdit = false) => {
    if (isEdit) {
      console.log(data);
      reset({
        ...data,
        dateTime: dayjs(data.fixtureTime).format('YYYY-MM-DDTHH:mm'),
      });
    } else {
      const wwbcClub = state?.clubs?.find((club) => club?.label === 'WWBC');
      reset({
        ...data,
        dateTime: dayjs(data.dateTime).format('YYYY-MM-DDTHH:mm'),
        venue: VENUE_TYPES[0].value,
        rinks: DEFAULT_RINK_TYPES[0].value,
        opponentId: state.clubs[0].id,
        eventId: state.events[0].id,
        homeTeamId: wwbcClub?.value,
        homeName: wwbcClub?.label,
      });
    }
    setIsEditMode(isEdit);
    setOpen(true);
  };

  const handleCSVUpload = async (event) => {
    event.preventDefault();

    if (file) {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', async function (event) {
        const text = event.target.result;
        const csvRows = text.slice(text.indexOf('\n') + 1).split('\n');

        let index = 0;
        let out = [];
        for (const rowItem of csvRows) {
          const values = rowItem
            .split(',')
            .map((item) =>
              item.replaceAll('"', '').replaceAll("'", '').replaceAll('\r', ''),
            );

          index += 1;

          if (index === 0) {
            continue;
          }

          out.push({
            dateTime: dayjs(
              `${values[0]?.trim()} ${values[1]?.trim()}`,
            ).toDate(),
            opponent: values[2]?.trim(),
            homeTeam: values[3]?.trim(),
            rinks: values[4]?.trim(),
            venue: values[5]?.trim(),
            eventName: values[6]?.trim(),
            ourScore: (values[7] || 0)?.toString(),
            opponentScore: (values[8] || 0)?.toString(),
            isJoinable: values[9]?.trim()?.toLowerCase() === 'yes',
            notes: values[10]?.trim(),
          });
        }
        await handleMultipleUserUpload(out);
      });

      fileReader.readAsText(file);
    } else {
      toast.warning('Please select a csv file');
    }
  };

  const handleMultipleUserUpload = async (fixtures) => {
    setMultipleFixtureError({
      message: '',
      items: [],
    });
    let result = await APIRequest('FIXTURES', 'POST', {
      encrypt: true,
      addToUrl: '/multiple',
      body: {
        fixtures: fixtures,
      },
    });
    if (result && result.ok) {
      toast.success('Multiple user added');
      fetchFixtureClubList();
      setFixtureUpload(false);
    } else {
      setMultipleFixtureError({
        message: result.message,
        items: result?.data || [],
      });
      toast.warning('Something went wrong.');
    }
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="flex flex-row items-center justify-end">
                    <div className="mb-8 mr-4">
                      <button
                        className="primary-btn"
                        onClick={() => setFixtureUpload(true)}
                      >
                        Upload Fixture
                      </button>
                    </div>
                    <div className="mb-8 mr-4">
                      <button
                        className="primary-btn"
                        onClick={() => openModal({}, false)}
                      >
                        Add Fixture
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex flex-col flex-wrap items-center justify-end md:flex-row">
                  <div className="mb-6 ml-4">
                    <label>Year</label>
                    <Select
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="year"
                      value={{ label: filters?.year, value: filters?.year }}
                      options={state.possibleYears?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(selected) => {
                        if (!selected) {
                          setFilters((previous) => ({
                            ...previous,
                            year: dayjs().year(),
                          }));
                        } else {
                          setFilters((previous) => ({
                            ...previous,
                            year: selected.value,
                          }));
                        }
                      }}
                      className="min-w-[300px] max-w-[400px]"
                    />
                  </div>

                  <div className="mb-6 ml-4">
                    <label>Event Name</label>
                    <Select
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="eventName"
                      value={{
                        label: filters?.eventName,
                        value: filters?.eventName,
                      }}
                      options={state.eventsToShow?.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      onChange={(selected) => {
                        if (!selected) {
                          setFilters((previous) => ({
                            ...previous,
                            eventName: 'All',
                          }));
                        } else {
                          setFilters((previous) => ({
                            ...previous,
                            eventName: selected.value,
                          }));
                        }
                      }}
                      className="min-w-[300px] max-w-[400px]"
                    />
                  </div>
                </div>
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Event
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Rinks
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Venue
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Score
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Home Team
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Opponent
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Fixture Time
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Interests
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Notes
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.dataToShow?.map((item) => (
                        <tr key={item?.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {item?.eventName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {item?.rinks}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>{item?.venue}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div className="flex items-center">
                              {item?.score ?? '-'} :{' '}
                              {item?.opponentScore ?? '-'}{' '}
                              {allowFixtureUpdate && (
                                <span
                                  className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                  onClick={() =>
                                    setScoreModal({ show: true, data: item })
                                  }
                                >
                                  <AiFillEdit />
                                </span>
                              )}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>{item?.homeName}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>{item?.opponentName}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>
                              {dayjs(item?.fixtureTime).format(
                                'ddd D MMM, YYYY',
                              )}
                              <br />
                              {dayjs(item.fixtureTime).format('hh:mm a')}
                            </div>
                          </td>
                          <td
                            className="flex cursor-pointer items-center whitespace-nowrap px-3 py-4 text-sm text-gray-900"
                            onClick={() =>
                              setUserInterestModal({
                                show: true,
                                data: item?.interestedUsers,
                              })
                            }
                          >
                            <span className="pr-1">
                              {item?.interestedUserIDs?.length}
                            </span>
                            <AiFillEye />
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 ">
                            <p className="w-[100px] truncate">{item?.notes}</p>
                          </td>
                          <td>
                            <div className="flex items-center self-center">
                              {allowUpdate && (
                                <span
                                  className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                  onClick={() => openModal(item, true)}
                                >
                                  <AiFillEdit />
                                </span>
                              )}

                              {allowDelete && (
                                <span
                                  className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <AiFillDelete />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CommonModal open={open} setOpen={() => setOpen(false)}>
        <form onSubmit={handleSubmit(handleFixtureCall)}>
          <h4 className="mb-4 text-center font-bold">
            {isEditMode ? 'Edit fixture' : 'Add a new fixture'}
          </h4>
          <div className="mb-2">
            <label className="text-sm text-gray-500">Home Team</label>
            <Select
              classNamePrefix="select"
              defaultValue={state.clubs.find(
                (item) => item.label === getValues('homeName'),
              )}
              isClearable={true}
              isSearchable={true}
              name="homeTeamId"
              options={state.clubs}
              onChange={(selected) => {
                setValue('homeTeamId', selected.value);
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Opponent</label>
            <Select
              classNamePrefix="select"
              defaultValue={
                isEditMode
                  ? state.clubs.find(
                      (item) => item.label === getValues('opponentName'),
                    )
                  : null
              }
              isClearable={true}
              isSearchable={true}
              name="opponentId"
              options={state.clubs}
              onChange={(selected) => {
                setValue('opponentId', selected.value);
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Event</label>
            <Select
              classNamePrefix="select"
              defaultValue={
                isEditMode
                  ? state.events.find(
                      (item) => item.label === getValues('eventName'),
                    )
                  : null
              }
              isClearable={true}
              isSearchable={true}
              name="opponentId"
              options={state.events}
              onChange={(selected) => {
                setValue('eventId', selected.value);
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Rinks</label>
            <CreatableSelect
              classNamePrefix="select"
              defaultValue={
                isEditMode
                  ? { label: getValues('rinks'), value: getValues('rinks') }
                  : null
              }
              isClearable={true}
              isSearchable={true}
              name="rinks"
              options={DEFAULT_RINK_TYPES}
              onChange={(selected) => {
                setValue('rinks', selected.value);
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Venue</label>
            <Select
              classNamePrefix="select"
              defaultValue={
                isEditMode
                  ? VENUE_TYPES.find(
                      (item) => item.value === getValues('venue'),
                    )
                  : null
              }
              isClearable={true}
              isSearchable={true}
              name="venue"
              options={VENUE_TYPES}
              onChange={(selected) => {
                setValue('venue', selected.value);
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Fixture Date</label>
            <input
              name="dateTime"
              {...register('dateTime', { required: true })}
              className="w-full border p-3"
              type="datetime-local"
              defaultValue={
                isEditMode
                  ? dayjs(getValues('dateTime')).format('YYYY-MM-DDTHH:mm:ss')
                  : null
              }
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Notes</label>
            <textarea
              name="notes"
              {...register('notes', { required: false })}
              className="w-full border p-3"
              rows="4"
              cols="30"
              defaultValue={isEditMode ? getValues('notes') : null}
            />
          </div>

          <div className="my-2 flex items-center">
            <input
              name="isJoinable"
              {...register('isJoinable')}
              className="mr-2 h-[15px] w-[15px] cursor-pointer border p-3 "
              defaultChecked={isEditMode ? getValues('isJoinable') : true}
              type="checkbox"
            />
            <span className="text-gray-600">Can users join this event?</span>
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value={isEditMode ? 'Save Fixture' : 'Add Fixture'}
            />
          </div>
        </form>
      </CommonModal>

      <CommonModal
        open={scoreModal?.show}
        setOpen={() => setScoreModal({ show: false })}
        modalClasses="md:min-w-[400px]"
      >
        <FixtureScoreEditModal
          open={scoreModal?.show}
          data={scoreModal?.data}
          callBackFunction={() => {
            fetchFixtureClubList();
            setScoreModal({ show: false });
          }}
        />
      </CommonModal>

      {/* Player Interest */}
      <CommonModal
        open={userInterestModal?.show}
        setOpen={() => setUserInterestModal({ show: false })}
        modalClasses="md:min-w-[400px]"
      >
        <div>
          <h4 className="mb-4 text-center font-bold">Interested Users</h4>
          {userInterestModal?.data?.length > 0 ? (
            <>
              {userInterestModal?.data?.map((item, index) => (
                <div key={`User-${index}`}>
                  {item?.firstName} {item?.lastName} ({item?.email})
                </div>
              ))}
            </>
          ) : (
            <p className="text-center text-gray-500">No User Interest Found</p>
          )}
        </div>
      </CommonModal>

      {/* Multiple Fixtuer Upload CSV */}
      <CommonModal
        open={fixtueUpload}
        setOpen={() => setFixtureUpload(false)}
        modalClasses="min-w-full md:min-w-[400px]"
      >
        <h4 className="mb-4 text-center font-bold">Fixture File Upload</h4>
        <CSVLink
          data={CSV_DATA}
          headers={CSV_HEADERS}
          filename="fixture-data.csv"
          className="ml-auto block w-fit"
        >
          <div className="text-primary underline">Download sample file</div>
        </CSVLink>
        <form onSubmit={handleCSVUpload}>
          <div className="my-6">
            <input
              type="file"
              accept=".csv"
              className="w-fit-content "
              onChange={(event) => setFile(event.target.files[0])}
            />
          </div>
          {multipleFixtureError?.message?.length > 0 && (
            <>
              <div className="text-red-600">{multipleFixtureError.message}</div>
              <div className="flex flex-wrap text-[13px] text-red-600">
                {multipleFixtureError?.items?.slice(0, 10)?.join(', ')}
              </div>
            </>
          )}
          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value="Save"
              disabled={!file}
            />
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default AdminFixturesSection;
