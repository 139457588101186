import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import APIRequest from '../../helpers/api-request';
import { toast } from 'react-toastify';
import Spinner from '../common/spinner';
import { AUDIENCE_OPTIONS, QUILL_FORMATS } from '../../helpers/constant';
import { parseImageURL } from '../../helpers/functions';
import dayjs from 'dayjs';

const NewsEditModal = ({ open, data }) => {
  const [quillState, setQuillState] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);

  const reactQuillRef = React.useRef(null);

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        handlers: {
          image: async () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.click();

            input.onchange = async () => {
              if (input.files && input.files.length > 0) {
                const item = input.files[0];
                const formData = new FormData();
                formData.append('image', item);
                toast.success('Uploading Image...');

                const result = await APIRequest('IMAGE_UPLOAD', 'POST', {
                  encrypt: true,
                  body: formData,
                  formData: true,
                });

                if (result && result.ok) {
                  const quill = reactQuillRef?.current?.getEditor();
                  const cursorPosition = quill.getSelection().index;
                  const link = parseImageURL(result.data.imagePath);

                  // max-width: 100%;
                  quill.setSelection(cursorPosition + 1);
                  quill.insertEmbed(cursorPosition, 'image', link);

                  quill.setSelection(cursorPosition + 1);
                } else {
                  toast.error('Failed to upload image.');
                }
              }
            };
          },
        },
      },
    }),
    [reactQuillRef],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm();

  React.useEffect(() => {
    if (open) {
      reset({
        ...data,
        publishedDate: dayjs(data?.publishedDate)?.format('YYYY-MM-DD'),
      });

      if (typeof data.content !== 'string') {
        setQuillState('');
      }
      // TO HANDLE OLD STRING DATA
      else {
        setQuillState(data.content);
      }
      setIsLoading(false);
    }
  }, [open]);

  const handlePostsCall = async (data) => {
    let result = await APIRequest('NEWS', 'PUT', {
      encrypt: true,
      addToUrl: `/${data.id}`,
      body: {
        ...data,
        content: quillState,
      },
    });

    if (result && result.ok) {
      toast.success('Edit the news successfully');

      window.location?.reload();
    } else {
      toast.warning('Failed to update news item');
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePostsCall)}>
      <h4 className="mb-4 text-center font-bold">Edit Post</h4>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Subject
              {errors?.subject && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Subject must be at least 5 letters.)
                </span>
              )}
            </label>
            <input
              {...register('subject', { required: true, minLength: 5 })}
              className="w-full border p-3"
              type="text"
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Display Date</label>
            <input
              name="publishedDate"
              {...register('publishedDate', { required: true })}
              className="w-full border p-3"
              type="date"
              defaultValue={
                dayjs(getValues('publishedDate')).format('YYYY-MM-DD') ||
                dayjs(data?.publishedDate).format('YYYY-MM-DD')
              }
              placeholder={getValues('publishedDate')}
            />
          </div>

          <div>
            <label className="text-sm text-gray-500">Audience</label>
            <select
              name="audience"
              className="w-full border p-3"
              onChange={(event) => {
                setValue('audience', event.target.value);
              }}
              defaultValue={getValues('audience') || AUDIENCE_OPTIONS[0]?.value}
            >
              {AUDIENCE_OPTIONS.map((item) => (
                <option key={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Content</label>
            <ReactQuill
              theme="snow"
              value={quillState}
              onChange={setQuillState}
              modules={modules}
              ref={reactQuillRef}
              formats={QUILL_FORMATS}
              className="relative h-[600px] bg-white"
            />
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value={'Save news'}
            />
          </div>
        </>
      )}
    </form>
  );
};

NewsEditModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    subject: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    publishedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

export default NewsEditModal;
