import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import APIRequest from '../helpers/api-request';
import Spinner from '../components/common/spinner';
import dayjs from 'dayjs';
import NewsSection from '../components/news/news-section';
import UserContext from '../contexts/user-context/user-context';
import { hasPermission } from '../helpers/functions';
import { PERMISSON_TYPES } from '../helpers/constant';
import CommonModal from '../components/common/modal';
import NewsEditModal from '../components/admin/news-edit-modal';

const formatNewsData = (data) => {
  let upcomingNews = [];
  let generalNews = [];

  for (const newsItem of data) {
    if (dayjs(newsItem.publishedDate).isAfter(dayjs())) {
      upcomingNews.push(newsItem);
    } else {
      generalNews.push(newsItem);
    }
  }

  return { upcomingNews, generalNews };
};

const NewsPage = () => {
  const [newsState, setNewsState] = useState({
    name: 'loading',
    data: {},
  });
  const [modalState, setModalState] = useState({
    show: false,
    data: {},
  });

  const { permissions = [] } = React.useContext(UserContext);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.NEWS_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.NEWS_UPDATE, permissions));
    }
  }, [permissions]);

  useEffect(() => {
    fetchNewsData();
  }, []);

  const fetchNewsData = async () => {
    const result = await APIRequest('NEWS', 'GET', {
      encrypt: true,
    });
    if (!result || !result.ok) {
      toast.warning(result.message);
      return;
    }

    const formattedNews = formatNewsData(result.data);

    setNewsState((previous) => ({
      ...previous,
      name: 'results',
      data: formattedNews,
    }));
  };

  return (
    <div className="mt-32 px-8 pt-8">
      {newsState.name === 'loading' && <Spinner />}
      {newsState.name === 'results' && (
        <>
          {newsState.data?.upcomingNews?.length > 0 && (
            <NewsSection
              title="Upcoming Events"
              news={newsState?.data?.upcomingNews}
              allowUpdate={allowUpdate}
              setModalState={setModalState}
            />
          )}

          {newsState.data?.generalNews?.length > 0 && (
            <NewsSection
              title="News"
              news={newsState?.data?.generalNews}
              allowUpdate={allowUpdate}
              setModalState={setModalState}
            />
          )}
        </>
      )}
      <CommonModal
        open={modalState.show}
        setOpen={() => setModalState({ show: false, data: {} })}
        modalClasses="md:min-w-[800px]"
      >
        <NewsEditModal data={modalState?.data} open={modalState.show} />
      </CommonModal>
    </div>
  );
};

export default NewsPage;
