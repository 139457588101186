import 'react-image-lightbox/style.css';

import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { PERMISSON_TYPES, getYearOptions } from '../../helpers/constant';
import { hasPermission, parseImageURL } from '../../helpers/functions';

import APIRequest from '../../helpers/api-request';
import CommonModal from '../common/modal';
import CreatableSelect from 'react-select/creatable';
import { GrOverview } from 'react-icons/gr';
import Lightbox from 'react-image-lightbox';
import React from 'react';
import Select from 'react-select';
import Spinner from '../common/spinner';
import TitleDivider from '../common/title-divider';
import UserContext from '../../contexts/user-context/user-context';
import YearlyGalleryGrid from '../gallery/yearly-grid';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const YEAR_OPTIONS = getYearOptions();

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const AdminGallerySection = () => {
  const { permissions = [] } = React.useContext(UserContext);

  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [modalValues, setModalValues] = React.useState({});
  const [allowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_CREATE, permissions),
  );
  const [currentImage, setCurrentImage] = React.useState();
  const [files, setFiles] = React.useState([]);
  const [addPhotosModal, setAddPhotosModal] = React.useState(false);

  React.useEffect(() => {
    fetchGalleryList();
  }, []);

  React.useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const fetchGalleryList = async () => {
    setState({
      name: 'loading',
    });
    const result = await APIRequest('GALLERIES', 'GET', { encrypt: true });
    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data.sort(
          (first, second) =>
            Number.parseInt(second.title) - Number.parseInt(first.title),
        ),
        existingYears: result.data.map((item) => item?.title),
      });
    } else {
      setState({
        name: 'invalid',
        message: result.message,
      });
    }
  };

  const handleImagesUpload = async (event) => {
    try {
      event.preventDefault();

      const result = await Promise.all(
        files.map((file) => {
          const formData = new FormData();
          formData.append('image', file);
          return APIRequest('IMAGE_UPLOAD', 'POST', {
            encrypt: true,
            body: formData,
            formData: true,
          });
        }),
      );
      let newUrls = [];
      let index = 0;
      for (const item of result) {
        if (!item || !item.ok) {
          toast.warning('Failed to upload:', files[index].name);
        } else {
          newUrls.push({ url: item.data?.imagePath, tags: [] });
        }
        index += 1;
      }
      setModalValues((previous) => ({
        ...previous,
        photos: previous.photos.concat(...newUrls),
      }));
      setFiles([]);
    } catch (error) {
      toast.warning(error?.message || 'Something went wrong.');
    }
  };

  const handleGalleryCall = async (event) => {
    event.preventDefault();
    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }

    const photos = modalValues.photos.map((item) => {
      if (item.index) {
        delete item.index;
      }
      return item;
    });

    let result = isEditMode
      ? await APIRequest('GALLERIES', 'PUT', {
          encrypt: true,
          addToUrl: `/${modalValues.id}`,
          body: {
            photos,
          },
        })
      : await APIRequest('GALLERIES', 'POST', {
          encrypt: true,
          body: {
            title: modalValues.title?.toString(),
            photos,
          },
        });

    if (result && result.ok) {
      toast.success('Added new images successfully');

      setOpen(false);
      fetchGalleryList();
    } else {
      toast.warning('Failed to add new images');
    }
  };

  const openModal = (data, isEdit = false) => {
    setIsEditMode(isEdit);

    if (isEdit) {
      setModalValues({
        ...data,
        photos: data?.photos?.map((item, index) => ({
          ...item,
          index: index + 1,
        })),
      });
    } else {
      setModalValues({
        title: dayjs().year(),
        photos: [],
      });
    }
    setOpen(true);
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      modalValues?.photos,
      result.source.index,
      result.destination.index,
    );

    let newData = [];
    for (const item of state.data) {
      if (item.title === modalValues?.title) {
        newData.push({
          ...item,
          photos: items,
        });
        setModalValues({
          ...item,
          photos: items,
        });
      }
      newData.push(item);
    }

    setState((previous) => {
      return {
        ...previous,
        data: newData,
      };
    });
  };

  const handlePhotosUpload = async (event) => {
    event.preventDefault();

    const photos = modalValues.photos.map((item) => {
      if (item.index) {
        delete item.index;
      }
      return item;
    });

    let result = await APIRequest('GALLERIES', 'PUT', {
      encrypt: true,
      addToUrl: `/${modalValues.id}`,
      body: {
        photos,
      },
    });

    if (result && result.ok) {
      toast.success('Added new images successfully');

      setAddPhotosModal(false);
      fetchGalleryList();
    } else {
      toast.warning('Failed to add new images');
    }
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="mb-8 ml-auto text-end">
                    <button
                      className="primary-btn"
                      onClick={() => openModal({}, false)}
                    >
                      Add New Year
                    </button>
                  </div>
                )}
                <div className="overflow-hidden ring-black ring-opacity-5 md:rounded-lg">
                  {state.data?.map((item) => (
                    <div key={`Gallery-${item?.title}`} className="mb-16 pb-16">
                      <div className="mb-4">
                        {item.title !== 'Favorites' && (
                          <span
                            className="flex w-fit cursor-pointer items-center text-gray-600 hover:text-primary"
                            onClick={() => openModal(item, true)}
                          >
                            Edit <AiFillEdit className="ml-1" />
                          </span>
                        )}
                        <div className="flex items-center">
                          <TitleDivider
                            title={item?.title}
                            headerClasses="text-gray-700"
                            openAddPhotosModal={() => {
                              setModalValues({
                                ...item,
                                photos: item?.photos?.map((item, index) => ({
                                  ...item,
                                  index: index + 1,
                                })),
                              });
                              setAddPhotosModal(true);
                            }}
                          />
                        </div>
                      </div>
                      <YearlyGalleryGrid
                        photos={item?.photos}
                        year={item?.title}
                        id={item?.id}
                        isAdmin={true}
                        refetchImages={fetchGalleryList}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add/Edit Modal for all */}
      <CommonModal
        open={open || false}
        setOpen={() => setOpen(false)}
        modalClasses="min-w-[90vw] relative "
        modalPosition="top"
      >
        <form onSubmit={handleGalleryCall} className="h-[80vh] overflow-auto ">
          <h4 className="mb-4 text-center font-bold">
            {isEditMode
              ? `Edit ${modalValues['title']} Gallery`
              : 'Add new year gallery'}
          </h4>

          {!isEditMode && (
            <div className="mb-2">
              <label className="text-sm text-gray-500">Year</label>
              {/* react select components */}

              <Select
                classNamePrefix="select"
                isSearchable={true}
                name="year"
                defaultValue={null}
                value={{ label: modalValues?.title, value: modalValues?.title }}
                options={YEAR_OPTIONS.filter(
                  (item) => !state.existingYears?.includes(item.value),
                )}
                onChange={(selected) => {
                  setModalValues((previous) => ({
                    ...previous,
                    title: selected.value,
                  }));
                }}
                className="min-w-[300px] max-w-[400px]"
              />
            </div>
          )}

          {/* Upload Section */}
          <div>
            <label className="mb-2 block text-sm text-gray-500">
              Upload New Images
            </label>
            <div>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                multiple={true}
                onChange={(event) => {
                  setFiles(
                    Object.values(event.target.files).filter(
                      (item) => item?.lastModified > 0,
                    ),
                  );
                }}
              />
            </div>
            {files?.length > 0 && (
              <div className="mt-4 flex flex-wrap items-center gap-4">
                {files?.map((file) => {
                  return (
                    <div key={file.name} className="mb-4">
                      <img
                        src={URL.createObjectURL(file)}
                        className="mr-4 h-[150px] w-auto border border-black p-2 "
                        alt="Upload"
                      />
                      <div
                        className="flex cursor-pointer items-center text-[12px] text-red-600"
                        onClick={() =>
                          setFiles((previous) =>
                            previous.filter(
                              (item) => item?.name !== file?.name,
                            ),
                          )
                        }
                      >
                        Delete <AiFillDelete />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="mt-4 mb-4 text-center ">
            {files.length > 0 ? (
              <button
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
                onClick={handleImagesUpload}
              >
                Upload Images
              </button>
            ) : (
              <button
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
              >
                {isEditMode ? 'Save Gallery' : 'Add Gallery'}
              </button>
            )}
          </div>

          <h2 className="mb-4 text-2xl font-bold">Current Photos</h2>
          <div className="mb-2">
            {/* react select components */}
            <div className="relative min-w-[90vw] overflow-auto">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="flex flex-row overflow-visible "
                    >
                      {modalValues.photos?.map((item, index) => {
                        const parsedUrl = parseImageURL(item?.url);
                        return (
                          <Draggable
                            key={`${parsedUrl}-${Math.random() * 1000000}`}
                            draggableId={parsedUrl}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                key={parsedUrl}
                                className="mb-8 flex flex-col items-center px-4"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="mb-2 w-full text-sm">
                                  <span>Order: </span>
                                  <input
                                    type="number"
                                    placeholder={index + 1}
                                    className="w-[100px] border p-3"
                                    onBlur={(event) => {
                                      const newIndex = Math.min(
                                        Math.max(
                                          Number.parseInt(event.target.value) -
                                            1,
                                          0,
                                        ),
                                        modalValues.photos.length - 1,
                                      );

                                      setModalValues((previous) => {
                                        const newPhotos = [...previous.photos];
                                        newPhotos.splice(
                                          newIndex,
                                          0,
                                          newPhotos?.splice(index, 1)[0],
                                        );
                                        return {
                                          ...previous,
                                          photos: [...newPhotos],
                                        };
                                      });
                                    }}
                                  />
                                </div>
                                <div className="mb-2 flex items-center justify-center gap-8 text-sm">
                                  <div
                                    className="flex cursor-pointer items-center text-primary"
                                    onClick={() => setCurrentImage(parsedUrl)}
                                  >
                                    Preview <GrOverview className="ml-1" />
                                  </div>

                                  <div
                                    className="flex cursor-pointer items-center text-red-400 hover:text-red-700"
                                    onClick={() =>
                                      setModalValues((previous) => ({
                                        ...previous,
                                        photos: previous.photos?.filter(
                                          (modalItem) =>
                                            modalItem?.url !== item?.url,
                                        ),
                                      }))
                                    }
                                  >
                                    Delete <AiFillDelete />
                                  </div>
                                </div>
                                <img
                                  src={parsedUrl}
                                  className="mx-auto h-auto max-w-[300px] object-cover"
                                  alt="Gallery"
                                  onClick={() => setCurrentImage(parsedUrl)}
                                  crossOrigin="anonymous"
                                />
                                <div className="text-center ">
                                  <label>Tags:</label>
                                  <CreatableSelect
                                    classNamePrefix="select"
                                    defaultValue={
                                      isEditMode
                                        ? item?.tags?.map((tag) => ({
                                            label: tag,
                                            value: tag,
                                          }))
                                        : null
                                    }
                                    className="z-10 mt-2 max-w-[300px]"
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    name="rinks"
                                    options={[]}
                                    onChange={(selected) => {
                                      setModalValues((previous) => ({
                                        ...previous,
                                        photos: previous.photos?.map(
                                          (currentItem) =>
                                            currentItem?.url === item?.url
                                              ? {
                                                  url: item?.url,
                                                  tags: selected.map(
                                                    (item) => item.value,
                                                  ),
                                                }
                                              : currentItem,
                                        ),
                                      }));
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
        </form>
      </CommonModal>

      {/* Add Photos Only Modal */}
      <CommonModal
        open={addPhotosModal || false}
        setOpen={() => setAddPhotosModal(false)}
        modalClasses="min-w-[90vw] relative "
        modalPosition="top"
      >
        <form
          onSubmit={handlePhotosUpload}
          className="min-h-[40vh] min-w-[90vw] overflow-auto"
        >
          <h4 className="mb-4 text-center font-bold">Add Photos</h4>

          {/* Upload Section */}
          <div>
            <label className="mb-2 block text-sm text-gray-500">
              Upload New Images
            </label>
            <div>
              <input
                type="file"
                accept="image/png, image/jpg, image/jpeg"
                multiple={true}
                onChange={(event) => {
                  setFiles(
                    Object.values(event.target.files).filter(
                      (item) => item?.lastModified > 0,
                    ),
                  );
                }}
              />
            </div>
            {files?.length > 0 && (
              <div className="mt-4 flex flex-wrap items-center gap-4">
                {files?.map((file) => {
                  return (
                    <div key={file.name} className="mb-4">
                      <img
                        src={URL.createObjectURL(file)}
                        className="mr-4 h-[150px] w-auto border border-black p-2 "
                        alt="Upload"
                      />
                      <div
                        className="flex cursor-pointer items-center text-[12px] text-red-600"
                        onClick={() =>
                          setFiles((previous) =>
                            previous.filter(
                              (item) => item?.name !== file?.name,
                            ),
                          )
                        }
                      >
                        Delete <AiFillDelete />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className="mt-4 text-center">
            {files.length > 0 ? (
              <button
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
                onClick={handleImagesUpload}
              >
                Upload Images
              </button>
            ) : (
              <button
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
              >
                Save
              </button>
            )}
          </div>
        </form>
      </CommonModal>

      {currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage}
          imageTitle={currentImage}
          mainSrcThumbnail={currentImage}
          onCloseRequest={() => setCurrentImage(null)}
          imageCrossOrigin="anonymous"
        />
      )}
    </>
  );
};

export default AdminGallerySection;
