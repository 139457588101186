import { AiFillEdit } from 'react-icons/ai';
import ContactForm from '../common/contact-form';
import { PERMISSON_TYPES } from '../../helpers/constant';
import PropTypes from 'prop-types';
import React from 'react';
import UserContext from '../../contexts/user-context/user-context';
import { hasPermission } from '../../helpers/functions';

const HomeContactUs = ({ contactInfo = {} }) => {
  const { permissions = [], setPostModalState } = React.useContext(UserContext);
  const [formattedDescription, setFormattedDescription] = React.useState(null);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (typeof contactInfo?.content !== 'string') {
      setFormattedDescription('');
    }
    // TO HANDLE OLD STRING DATA
    else {
      setFormattedDescription(contactInfo?.content);
    }
  }, [contactInfo?.content]);

  return (
    <div className="my-16">
      <h2 className="title-header">Contact Us</h2>
      <div className="mt-4 grid grid-cols-1 gap-8 md:grid-cols-2">
        <ContactForm />
        <div className="ml-0 md:ml-8">
          <div className="mb-8">
            {allowUpdate && (
              <span
                className="flex cursor-pointer items-center pl-2 text-sm capitalize"
                onClick={() =>
                  setPostModalState({
                    show: true,
                    data: contactInfo,
                  })
                }
              >
                <AiFillEdit />
                <span>Edit</span>
              </span>
            )}
            {formattedDescription && (
              <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
            )}
          </div>
          <div className="position-relative mt-8">
            <iframe
              height="350"
              title="WWBC_LOCATION"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d622.1258824910481!2d-0.2344978!3d51.4121764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487608df7f34dcdb%3A0x85babff4a786c5b0!2sWest%20Wimbledon%20Bowling%20Club!5e0!3m2!1sen!2snp!4v1691594581536!5m2!1sen!2snp"
              className="w-full border-0"
              allowFullScreen="true "
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeContactUs.propTypes = {
  contactInfo: PropTypes.shape({
    subject: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};
export default HomeContactUs;
