import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import CarouselImage1 from '../../assets/carousel-1.jpeg';
import CarouselImage2 from '../../assets/carousel-2.jpeg';
import CarouselImage3 from '../../assets/carousel-3.jpeg';
import CarouselImage4 from '../../assets/carousel-4.jpeg';
import CarouselImage5 from '../../assets/carousel-5.jpeg';

const HomeCarousel = () => {
  return (
    <div className="relative ">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={10000}
        showThumbs={false}
        showStatus={false}
        stopOnHover={true}
        swipeable={false}
      >
        <div>
          <img
            src={CarouselImage2}
            className="h-[800px] max-h-screen w-auto bg-gray-400 object-cover object-bottom"
            alt="Random Picsum"
          />
        </div>

        <div>
          <img
            src={CarouselImage3}
            className="h-[800px] max-h-screen w-auto bg-gray-400 object-cover object-center"
            alt="Random Picsum"
          />
        </div>
        <div>
          <img
            src={CarouselImage1}
            className="h-[800px] max-h-screen w-auto bg-gray-400 object-cover object-center"
            alt="Random Picsum"
          />
        </div>
        <div>
          <img
            src={CarouselImage4}
            className="h-[800px] max-h-screen w-auto bg-gray-400 object-cover object-center"
            alt="Random Picsum"
          />
        </div>
        <div>
          <img
            src={CarouselImage5}
            className="h-[800px] max-h-screen w-auto bg-gray-400 object-cover object-center"
            alt="Random Picsum"
          />
        </div>
      </Carousel>
      <div className="pointer-events-none absolute top-0 bottom-0 left-0 right-0 z-[2] bg-gradient-to-b from-gray-200 via-transparent " />
      <div className="pointer-events-none absolute top-0 bottom-0 left-0 right-0 z-[2] bg-gradient-to-t from-gray-400 via-transparent " />
      <div className="pointer-events-none absolute left-0 bottom-4 z-[2] mx-auto w-full text-5xl font-bold md:text-6xl">
        <div className="hero-title mx-auto max-w-[1920px] p-8">
          West Wimbledon <br />
          Bowling Club
        </div>
      </div>
    </div>
  );
};

export default HomeCarousel;
