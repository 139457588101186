import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import APIRequest from '../../helpers/api-request';
import { toast } from 'react-toastify';
import Spinner from '../common/spinner';

const FixtureScoreEditModal = ({ open, data, callBackFunction }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const { register, handleSubmit, reset } = useForm();

  React.useEffect(() => {
    if (open) {
      reset({
        ...data,
      });

      setIsLoading(false);
    }
  }, [open]);

  const handlePostsCall = async (formData) => {
    let result = await APIRequest('FIXTURES', 'PUT', {
      encrypt: true,
      addToUrl: `/${formData.id}/score`,
      body: {
        ourScore: formData?.score,
        opponentScore: formData?.opponentScore,
      },
    });

    if (result && result.ok) {
      toast.success('Edited score successfully');

      if (callBackFunction) {
        callBackFunction();
      } else {
        window.location?.reload();
      }
    } else {
      toast.warning('Failed to update score');
    }
  };

  return (
    <form onSubmit={handleSubmit(handlePostsCall)}>
      <h4 className="mb-4 text-center font-bold">Edit Post</h4>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="mb-2">
            <label className="text-sm text-gray-500">Our Score</label>
            <input
              name="score"
              {...register('score')}
              className="w-full border p-3"
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Opponent Score</label>
            <input
              name="opponentScore"
              {...register('opponentScore')}
              className="w-full border p-3"
            />
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8"
              value={'Save score'}
            />
          </div>
        </>
      )}
    </form>
  );
};

FixtureScoreEditModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    subject: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    publishedDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  callBackFunction: PropTypes.func,
};

export default FixtureScoreEditModal;
