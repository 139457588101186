import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import APIRequest from '../../helpers/api-request';
import CommonModal from '../common/modal';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { PERMISSON_TYPES } from '../../helpers/constant';
import React from 'react';
import Spinner from '../common/spinner';
import UserContext from '../../contexts/user-context/user-context';
import { hasPermission } from '../../helpers/functions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const AdminClubSection = () => {
  const { permissions = [] } = React.useContext(UserContext);
  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [allowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.CLUB_UPDATE, permissions),
  );
  const [allowDelete] = React.useState(
    hasPermission(PERMISSON_TYPES.CLUB_DELETE, permissions),
  );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.CLUB_CREATE, permissions),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  React.useEffect(() => {
    fetchClubData();
  }, []);

  const fetchClubData = async () => {
    setState((previous) => ({
      ...previous,
      name: 'loading',
    }));
    const result = await APIRequest('CLUBS', 'GET', { encrypt: true });

    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data,
      });
    } else {
      setState({
        name: 'invalid',
        message: result.message,
      });
    }
  };

  const handleClubCall = async (data) => {
    if (data.address?.latLong?.length <= 0) {
      data.address.latLong = null;
    }

    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }
    let result = isEditMode
      ? await APIRequest('CLUBS', 'PUT', {
          encrypt: true,
          addToUrl: `/${data.id}`,
          body: {
            ...data,
          },
        })
      : await APIRequest('CLUBS', 'POST', {
          encrypt: true,
          body: {
            ...data,
          },
        });

    if (result && result.ok) {
      toast.success('Added new club successfully');
      reset();
      setOpen(false);
      fetchClubData();
    } else {
      toast.warning('Failed to add new club');
    }
  };

  const handleDelete = async (id) => {
    if (allowDelete) {
      if (window.confirm('Are you sure you want to delete the club?')) {
        const result = await APIRequest('CLUBS', 'DELETE', {
          encrypt: true,
          addToUrl: `/${id}`,
        });
        if (result && result.ok) {
          toast.success('Deleted club successfully');
          fetchClubData();
        } else {
          toast.warning('Failed to delete club');
        }
      }
    }
  };

  const openModal = (data, isEdit = false) => {
    reset({
      ...data,
      address: {
        ...data?.address,
        latLong: data?.address?.latitude
          ? `${data?.address?.latitude},${data.address?.longitude}`
          : '',
      },
    });
    setIsEditMode(isEdit);
    setOpen(true);
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="mb-8 ml-auto text-end">
                    <button
                      className="primary-btn"
                      onClick={() => openModal({}, false)}
                    >
                      Add Club
                    </button>
                  </div>
                )}
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 "
                        >
                          Full Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Alias
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Link
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.data?.map((item) => (
                        <tr key={item?.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            {item.fullName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            {item.shortName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            {item.address?.name ?? '-'}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                            {item.link ? (
                              <a
                                href={item?.link}
                                className="bold flex items-center text-primary"
                              >
                                {item?.link}
                                <span className="pl-1">
                                  <HiOutlineExternalLink />
                                </span>
                              </a>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            <div className="flex items-center self-center">
                              {allowUpdate && (
                                <span
                                  className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                  onClick={() => openModal(item, true)}
                                >
                                  <AiFillEdit />
                                </span>
                              )}
                              {allowDelete && (
                                <span
                                  className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <AiFillDelete />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <CommonModal open={open} setOpen={() => setOpen(false)}>
            <form onSubmit={handleSubmit(handleClubCall)}>
              <h4 className="mb-4 text-center font-bold">
                {isEditMode ? 'Edit club' : 'Add a new club'}
              </h4>
              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Club Name
                  {errors?.fullName && (
                    <span className="pl-2 text-[12px] text-red-600">
                      (Club Name must be at least 3 letters.)
                    </span>
                  )}
                </label>
                <input
                  {...register('fullName', { required: true, minLength: 3 })}
                  className="w-full border p-3"
                  type="text"
                />
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Alias{' '}
                  {errors?.shortName && (
                    <span className="pl-2 text-[12px] text-red-600">
                      (Club Alias must be at least 3 letters.)
                    </span>
                  )}
                </label>
                <input
                  {...register('shortName', { required: true, minLength: 3 })}
                  className="w-full border p-3"
                />
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-500">Address </label>
                <input
                  {...register('address.name')}
                  className="w-full border p-3"
                  type="alias"
                />
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Lattitude and Longitude{' '}
                </label>
                <input
                  {...register('address.latLong', {})}
                  className="w-full border p-3"
                />
              </div>

              <div className="mb-2">
                <label className="text-sm text-gray-500">Link </label>
                <input {...register('link')} className="w-full border p-3" />
              </div>

              <div className="mt-4 text-center">
                <input
                  type="submit"
                  className="primary-btn cursor-pointer p-2 px-8 "
                  value={isEditMode ? 'Save Club' : 'Add Club'}
                />
              </div>
            </form>
          </CommonModal>
        </div>
      )}
    </>
  );
};

export default AdminClubSection;
