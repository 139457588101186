import { clamp, parseImageURL } from '../../helpers/functions';

import APIRequest from '../../helpers/api-request';
import Carousel, { Modal, ModalGateway } from 'react-images';
import PropTypes from 'prop-types';
import React from 'react';
import SingleImage from './single-image';
import { toast } from 'react-toastify';

const YearlyGalleryGrid = ({
  photos,
  year,
  id,
  isAdmin = false,
  refetchImages,
}) => {
  const [images, setImages] = React.useState([]);
  const [index, setIndex] = React.useState(-1);

  // const currentImage = images[index];
  // const nextIndex = (index + 1) % images.length;
  // const prevIndex = (index + images.length - 1) % images.length;

  const handleClick = (index) => {
    setIndex(index);
  };
  const handleClose = () => setIndex(-1);
  // const handleMovePrev = () => setIndex(prevIndex);
  // const handleMoveNext = () => setIndex(nextIndex);

  const isFavorite = year === 'Favorites';

  React.useEffect(() => {
    setImages(
      photos.map((item, index) => ({
        index: index,
        src: parseImageURL(item?.url + '?thumbnail=s'),
        srcSet: `${parseImageURL(
          item?.url + '?thumbnail=xs',
        )} 1x, ${parseImageURL(item?.url + '?thumbnail=s')} 2x`,
        original: parseImageURL(item?.url),
        width: clamp(Math.floor(Math.random() * 1000), 700, 1000),
        height: 800,
        tags: item?.tags?.map((tag) => ({ value: tag, title: tag })),
        isFavorite: item?.isFavorite || false,
        galleryId: item?.galleryId,
      })),
    );
  }, []);

  const handleImageFavoriteUpdate = async (photoDetails) => {
    // HANDLE FAVORITE OR UNFAVORITE IMAGE HERE
    const result = await APIRequest('GALLERIES', 'PUT', {
      encrypt: true,
      addToUrl: `/year/${photoDetails?.galleryId || id}/${photoDetails.index}`,
    });

    if (result && result.ok) {
      if (year === 'Favorites') {
        refetchImages();
      } else {
        setImages((previous) =>
          previous.map((item, index) => {
            if (index === photoDetails.index) {
              return {
                ...item,
                isFavorite: item.isFavorite ? !item.isFavorite : true,
              };
            }
            return item;
          }),
        );
      }
    } else {
      toast.warning(result.error || 'Something went wrong.');
    }
  };

  const galleryImages = !isFavorite
    ? images.filter((image) => !image.isFavorite)
    : images;

  return (
    <>
      <div className="flex flex-col flex-wrap items-center gap-1 sm:flex-row">
        {galleryImages.map((image, index) => (
          <SingleImage
            key={`gallery-${image}-${index}`}
            imageProps={image}
            handleImageFavoriteUpdate={handleImageFavoriteUpdate}
            handleClick={handleClick}
            isAdmin={isAdmin}
          />
        ))}
      </div>
      <ModalGateway>
        {index >= 0 ? (
          <Modal onClose={() => handleClose()}>
            <Carousel
              currentIndex={index}
              views={images.map((x) => ({
                ...x,
                srcset: x.src,
                source: x.src,
              }))}
            />
          </Modal>
        ) : (
          <></>
        )}
      </ModalGateway>
    </>
  );
};

YearlyGalleryGrid.propTypes = {
  photos: PropTypes.array,
  year: PropTypes.string,
  id: PropTypes.string,
  isAdmin: PropTypes.bool,
  refetchImages: PropTypes.func,
};

export default React.memo(YearlyGalleryGrid);
