import React from 'react';
import PropTypes from 'prop-types';
import { SlArrowUpCircle, SlArrowDownCircle } from 'react-icons/sl';
import { AiFillEdit } from 'react-icons/ai';

import { getFormattedText } from '../../helpers/functions';
import dayjs from 'dayjs';

const Accordion = ({
  data,
  isOpen = false,
  allowUpdate = false,
  setModalState,
}) => {
  const [open, setOpen] = React.useState(isOpen);

  return (
    <>
      <div className="mb-8 w-full border-b border-[#eee] px-4 py-8">
        <div className="flex items-center justify-between">
          <div className="font-light">
            {dayjs(data.publishedDate).format('ddd D MMM, YYYY')}
          </div>
          {allowUpdate && (
            <div
              className="flex cursor-pointer items-center pl-2 text-sm capitalize"
              onClick={() => {
                setModalState({
                  show: true,
                  data: data,
                });
              }}
            >
              <AiFillEdit />
              <span>Edit</span>
            </div>
          )}
        </div>
        <div
          className="flex w-full cursor-pointer flex-row items-center justify-between"
          onClick={() => setOpen(!open)}
        >
          <div className="w-[80%] text-4xl font-bold">{data?.subject}</div>
          <div className="ml-auto text-end text-3xl">
            {open ? <SlArrowUpCircle /> : <SlArrowDownCircle />}
          </div>
        </div>
        {open && (
          // Fade In animation
          <div className="text w-100 mt-8 text-justify md:w-[80%] ">
            <div
              dangerouslySetInnerHTML={{
                __html: getFormattedText(data?.content),
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

Accordion.propTypes = {
  data: PropTypes.shape({
    subject: PropTypes.string,
    content: PropTypes.string,
    publishedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  isOpen: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  setModalState: PropTypes.func,
};

export default Accordion;
