import React from 'react';
import { useForm } from 'react-hook-form';
import { IoMdArrowBack } from 'react-icons/io';
import { PATHS } from '../helpers/constant';
import wwbcLogo from '../assets/wwbc-logo.png';
import APIRequest from '../helpers/api-request';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/common/spinner';

const ForgotPasswordPage = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (values) => {
    const result = await APIRequest('FORGOT_PASSWORD', 'POST', {
      body: values,
    });
    if (!result || !result.ok) {
      toast.warning(result?.message ?? 'Something went wrong.');
    } else {
      toast.success('Please check your email to continue.');
      reset();
    }
  };

  return (
    <>
      <div className="relative mx-auto min-h-screen max-w-[1400px]">
        <div className="mb-8 p-8">
          <a href={PATHS.HOME} className=" cursor-pointer">
            <img
              src={wwbcLogo}
              alt="logo"
              className=" mx-auto h-auto w-[80px]"
            />
          </a>
          <h1 className="mt-4 text-center text-3xl font-bold">
            WWBC Bowling Club
          </h1>
        </div>
        <div className="mx-auto grid w-full max-w-[400px] grid-cols-1 gap-8 px-8">
          <div className="">
            <h4 className="text-lg font-bold">Enter your email address.</h4>
            <p className="mb-4">
              An email will be sent to your email address to reset your
              password.
            </p>
            {isSubmitting ? (
              <div className="mb-8">
                <Spinner additionalClasses="" />
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                <div className="mb-6">
                  <input
                    {...register('email', { required: true, minLength: 3 })}
                    className="w-full max-w-[400px] border p-3"
                    type="email"
                    placeholder="john.doe@mail.com"
                  />
                </div>

                <div>
                  <input
                    type="submit"
                    className="primary-btn cursor-pointer p-2 px-8 text-sm"
                    value="RESET PASSWORD"
                  />
                </div>
              </form>
            )}
            <p className="mb-4 text-gray-500">
              {
                "If you don't have an email address, please contact the club committee to reset the password for you."
              }
            </p>
            <a href={PATHS.LOGIN} className="inline-flex items-center">
              <span className="pr-2 text-xl">
                <IoMdArrowBack />
              </span>
              Go Back
            </a>
          </div>

          <div></div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6500}
        closeOnClick
        closeButton
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ForgotPasswordPage;
