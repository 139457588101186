import { AbortController } from 'node-abort-controller';

import { LOCAL_STORAGE_KEYS, BACKEND_URL } from './constant';
import API_URLS from './urls';

const APIRequest = async (
  urlKey,
  method,
  {
    encrypt = false,
    body = {},
    headers = {},
    timeout = 0,
    addToUrl = '',
    formData = false,
  },
) => {
  let timerId;
  if (encrypt) {
    const userToken = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_TOKEN);
    if (userToken) {
      headers['Authorization'] = `Bearer ${userToken}`;
    }
  }
  let url = `${BACKEND_URL}/${API_URLS[urlKey]}`;

  try {
    const parameters = formData
      ? {
          method,
          headers: {
            ...headers,
          },
          body: body,
        }
      : {
          method,
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
          body: JSON.stringify(body),
        };

    if (timeout > 0) {
      const controller = new AbortController();
      timerId = setTimeout(() => controller.abort(), timeout);
      parameters.signal = controller.signal;
    }

    if (addToUrl?.length > 0) {
      url += addToUrl;
    }

    if (method === 'GET') {
      delete parameters.body;
    }
    const result = await fetch(url, parameters);
    if (timerId) {
      clearTimeout(timerId);
    }

    let json = {};
    if (result.status >= 200 && result.status < 300) {
      if (result.status === 204) {
        return { ok: true };
      }
      json = await result.json();
      return { ok: true, data: json };
    } else {
      json = await result.json();
      return {
        ok: false,
        message:
          json?.message ??
          json.error ??
          result.statusText ??
          'Something went wrong.',
        data: json?.data,
      };
    }
  } catch (error) {
    console.log(error);
    return { ok: false, message: error?.message };
  }
};

export default APIRequest;
