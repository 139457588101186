import { PERMISSION_MATRIX, PERMISSON_TYPES } from '../../../helpers/constant';

import PropTypes from 'prop-types';
import React from 'react';

const UserPermissionMatrix = ({ userPermissions, setUserPermissions }) => {
  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const [isReadChecked, setIsReadChecked] = React.useState(false);
  const [isUpdateChecked, setIsUpdateChecked] = React.useState(false);
  const [isCreateChecked, setIsCreateChecked] = React.useState(false);
  const [isDeleteChecked, setIsDeleteChecked] = React.useState(false);
  const readPermissions = Object.values(PERMISSON_TYPES).filter((item) =>
    item.includes('_read'),
  );
  const updatePermissions = Object.values(PERMISSON_TYPES).filter((item) =>
    item.includes('update'),
  );
  const createPermissions = Object.values(PERMISSON_TYPES).filter((item) =>
    item.includes('_create'),
  );
  const deletePermissions = Object.values(PERMISSON_TYPES).filter((item) =>
    item.includes('_delete'),
  );

  React.useEffect(() => {
    if (userPermissions.length === Object.values(PERMISSON_TYPES).length) {
      setIsAllChecked(true);
      setIsReadChecked(true);
      setIsUpdateChecked(true);
      setIsCreateChecked(true);
      setIsDeleteChecked(true);
    } else {
      setIsAllChecked(false);
      setIsReadChecked(
        userPermissions.filter((item) => item.includes('_read')).length ===
          readPermissions.length,
      );
      setIsUpdateChecked(
        userPermissions.filter((item) => item.includes('_update')).length ===
          updatePermissions.length,
      );
      setIsCreateChecked(
        userPermissions.filter((item) => item.includes('_create')).length ===
          createPermissions.length,
      );
      setIsDeleteChecked(
        userPermissions.filter((item) => item.includes('_delete')).length ===
          deletePermissions.length,
      );
    }
  }, [userPermissions]);

  const handleInputChange = (permission) => {
    setUserPermissions((previous) =>
      previous.includes(permission)
        ? previous.filter((item) => item !== permission)
        : [...previous, permission],
    );
  };

  const handleMultipleSelect = (type) => {
    if (type === 'ALL') {
      if (userPermissions?.length === Object.values(PERMISSON_TYPES).length) {
        setUserPermissions([]);
      } else {
        setUserPermissions(Object.values(PERMISSON_TYPES));
      }
    } else if (type === 'READ') {
      const includesAllReadPermission =
        userPermissions.filter((item) => item.includes('_read')).length ===
        readPermissions.length;
      if (includesAllReadPermission) {
        setUserPermissions((previous) =>
          previous.filter((item) => !item.includes('_read')),
        );
      } else {
        setUserPermissions((previous) => [
          ...new Set([...previous, ...readPermissions]),
        ]);
      }
    } else if (type === 'CREATE') {
      const includesAllCreatePermissions =
        userPermissions.filter((item) => item.includes('_create')).length ===
        createPermissions.length;
      if (includesAllCreatePermissions) {
        setUserPermissions((previous) =>
          previous.filter((item) => !item.includes('_create')),
        );
      } else {
        setUserPermissions((previous) => [
          ...new Set([...previous, ...createPermissions]),
        ]);
      }
    } else if (type === 'UPDATE') {
      const includesAllUpdatePermission =
        userPermissions.filter((item) => item.includes('_update')).length ===
        updatePermissions.length;

      if (includesAllUpdatePermission) {
        setUserPermissions((previous) =>
          previous.filter((item) => !item.includes('_update')),
        );
      } else {
        setUserPermissions((previous) => [
          ...new Set([...previous, ...updatePermissions]),
        ]);
      }
    } else if (type === 'DELETE') {
      const includesAllDeletePermission =
        userPermissions.filter((item) => item.includes('_delete')).length ===
        deletePermissions.length;
      if (includesAllDeletePermission) {
        setUserPermissions((previous) =>
          previous.filter((item) => !item.includes('_delete')),
        );
      } else {
        setUserPermissions((previous) => [
          ...new Set([...previous, ...deletePermissions]),
        ]);
      }
    }
  };

  return (
    <>
      <div className="flex items-center justify-end pr-9">
        <p className="text-sm text-gray-500">
          Select All{' '}
          <input
            type="checkbox"
            checked={isAllChecked}
            defaultChecked={userPermissions.includes('product_owner')}
            className="h-[15px] w-[15px]"
            onClick={() => handleMultipleSelect('ALL')}
          />
        </p>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th className="mr-2 w-1/3"></th>
            <th className="w-1/6 text-center">Read</th>
            <th className="w-1/6 text-center">Create</th>
            <th className="w-1/6 text-center">Update</th>
            <th className="w-1/6 text-center">Delete</th>
          </tr>
        </thead>
        <tbody key={userPermissions.length}>
          <tr className="h-12">
            <td className="text-end text-sm italic">Select All</td>
            <td className="cursor-pointer text-center align-middle text-sm text-primary">
              <input
                type="checkbox"
                checked={isReadChecked}
                className="h-[15px] w-[15px]"
                onClick={() => handleMultipleSelect('READ')}
              />
            </td>
            <td className="cursor-pointer text-center align-middle text-sm text-primary">
              <input
                type="checkbox"
                checked={isCreateChecked}
                className="h-[15px] w-[15px]"
                onClick={() => handleMultipleSelect('CREATE')}
              />
            </td>
            <td className="cursor-pointer text-center align-middle text-sm text-primary">
              <input
                type="checkbox"
                checked={isUpdateChecked}
                className="h-[15px] w-[15px]"
                onClick={() => handleMultipleSelect('UPDATE')}
              />
            </td>
            <td className="cursor-pointer text-center align-middle text-sm text-primary">
              <input
                type="checkbox"
                checked={isDeleteChecked}
                className="h-[15px] w-[15px]"
                onClick={() => handleMultipleSelect('DELETE')}
              />
            </td>
          </tr>
          {PERMISSION_MATRIX.map((row, index) => (
            <tr
              key={`Permission matrix-${index}`}
              className="h-7 bg-white even:bg-gray-100"
            >
              <td className="align-middle">{row[0]}</td>
              <td className="text-center align-middle">
                {row[1] && (
                  <input
                    type="checkbox"
                    defaultChecked={
                      userPermissions.includes(row[1]) ||
                      userPermissions.includes('product_owner')
                    }
                    className="h-[15px] w-[15px]"
                    onClick={() => handleInputChange(row[1])}
                  />
                )}
              </td>

              <td className="text-center align-middle">
                {row[2] && (
                  <input
                    type="checkbox"
                    defaultChecked={
                      userPermissions.includes(row[2]) ||
                      userPermissions.includes('product_owner')
                    }
                    className="h-[15px] w-[15px]"
                    onClick={() => handleInputChange(row[2])}
                  />
                )}
              </td>
              <td className="text-center align-middle">
                {row[3] && (
                  <input
                    type="checkbox"
                    defaultChecked={
                      userPermissions.includes(row[3]) ||
                      userPermissions.includes('product_owner')
                    }
                    className="h-[15px] w-[15px]"
                    onClick={() => handleInputChange(row[3])}
                  />
                )}
              </td>
              <td className="text-center align-middle">
                {row[4] && (
                  <input
                    type="checkbox"
                    defaultChecked={
                      userPermissions.includes(row[4]) ||
                      userPermissions.includes('product_owner')
                    }
                    className="h-[15px] w-[15px]"
                    onClick={() => handleInputChange(row[4])}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

UserPermissionMatrix.propTypes = {
  userPermissions: PropTypes.array,
  setUserPermissions: PropTypes.func,
};

export default UserPermissionMatrix;
