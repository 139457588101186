import React from 'react';
import { useForm } from 'react-hook-form';
import { IoMdArrowBack } from 'react-icons/io';
import { PATHS } from '../helpers/constant';
import wwbcLogo from '../assets/wwbc-logo.png';
import APIRequest from '../helpers/api-request';
import { toast, ToastContainer } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import Spinner from '../components/common/spinner';
import PasswordInput from '../components/common/password-input';
import { randomPassword } from '../helpers/functions';

const ResetPasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [searchParams] = useSearchParams();
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState('');

  const onSubmit = async (values) => {
    if (values?.password?.length !== values.confirmPassword?.length) {
      toast.warning('Password do not match.');
      return;
    }
    if (values?.password !== values.confirmPassword) {
      toast.warning('Password do not match.');
      return;
    }

    const result = await APIRequest('RESET_PASSWORD', 'POST', {
      body: { ...values, token: searchParams.get('token') },
    });
    if (!result || !result.ok) {
      toast.warning('Something went wrong.');
      setPasswordError(
        'Please confirm your password has at least one uppercase letter and one lowercase letter.',
      );
      setTimeout(() => {
        setPasswordError('');
      }, 6000);
    } else {
      toast.success('Password successfully updated.');
      setPasswordChanged(true);
    }
  };

  return (
    <>
      <div className="relative mx-auto min-h-screen max-w-[500px]">
        <div className="mb-8 p-8">
          <a href={PATHS.HOME} className=" cursor-pointer">
            <img
              src={wwbcLogo}
              alt="logo"
              className=" mx-auto h-auto w-[80px]"
            />
          </a>
          <h1 className="mt-4 text-center text-3xl font-bold">
            WWBC Bowling Club
          </h1>
        </div>
        <div className="grid w-full grid-cols-1 gap-8 px-8">
          {!searchParams.get('token') ||
          searchParams.get('token')?.length <= 0 ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <div className="">
              {passwordChanged ? (
                <>
                  <a href={PATHS.LOGIN} className="inline-flex items-center">
                    <span className="pr-2 text-xl">
                      <IoMdArrowBack />
                    </span>
                    Go to Login Page
                  </a>
                </>
              ) : (
                <>
                  <h4 className="text-lg font-bold">Create new password</h4>

                  <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                    <div
                      onClick={() => {
                        const password = randomPassword();
                        setValue('password', password);
                        setValue('confirmPassword', password);
                      }}
                      className="cursor-pointer text-start text-[12px] underline"
                    >
                      Generate Random Password
                    </div>
                    <div className="mb-2 max-w-[400px]">
                      <label className="text-sm text-gray-500">
                        New password
                        {errors?.password && (
                          <span className="pl-2 text-[12px] text-red-600">
                            (Password must be at least 8 letters.)
                          </span>
                        )}
                      </label>
                      <PasswordInput register={register} name="password" />
                    </div>

                    <div className="mb-2 max-w-[400px]">
                      <label className="text-sm text-gray-500">
                        Confirm password
                        {errors?.confirmPassword && (
                          <span className="pl-2 text-[12px] text-red-600">
                            (Password must be at least 8 letters.)
                          </span>
                        )}
                      </label>
                      <PasswordInput
                        register={register}
                        name="confirmPassword"
                      />
                    </div>

                    {passwordError?.length > 0 && (
                      <span className="text-[13px] text-red-600">
                        {passwordError}
                      </span>
                    )}

                    <div className="mt-4">
                      <input
                        type="submit"
                        className="primary-btn cursor-pointer p-2 px-8 text-sm"
                        value="RESET PASSWORD"
                      />
                    </div>
                  </form>
                  <a href={PATHS.LOGIN} className="inline-flex items-center">
                    <span className="pr-2 text-xl">
                      <IoMdArrowBack />
                    </span>
                    Go Back
                  </a>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6500}
        closeOnClick
        closeButton
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ResetPasswordPage;
