import { BACKEND_URL, PERMISSON_TYPES } from '../../helpers/constant';

import APIRequest from '../../helpers/api-request';
import { AiFillDelete } from 'react-icons/ai';
import CommonModal from '../common/modal';
import React from 'react';
import Spinner from '../common/spinner';
import UserContext from '../../contexts/user-context/user-context';
import dayjs from 'dayjs';
import { hasPermission } from '../../helpers/functions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const AdminClubDocumentsSection = () => {
  const { permissions = [] } = React.useContext(UserContext);

  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [open, setOpen] = React.useState(false);

  const [allowDelete] = React.useState(
    hasPermission(PERMISSON_TYPES.CLUB_DOCUMENTS_DELETE, permissions),
  );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.CLUB_DOCUMENTS_CREATE, permissions),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  React.useEffect(() => {
    fetchDocumentList();
  }, []);

  const fetchDocumentList = async () => {
    setState({
      name: 'loading',
    });
    const result = await APIRequest('CLUB_DOCUMENTS', 'GET', {
      encrypt: true,
    });

    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data,
      });
    } else {
      setState({
        name: 'invalid',
      });
    }
  };

  const openModal = () => {
    reset();
    setOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete the user?')) {
      const result = await APIRequest('CLUB_DOCUMENTS', 'DELETE', {
        encrypt: true,
        addToUrl: `/${id}`,
      });
      if (result && result.ok) {
        toast.success('Deleted document successfully');
        fetchDocumentList();
      } else {
        toast.warning('Something went wrong.');
      }
    }
  };

  const handleDocumentAdd = async (data) => {
    try {
      const formData = new FormData();
      formData.append('document', data.file[0]);
      formData.append('title', data.title);
      const result = await APIRequest('CLUB_DOCUMENTS', 'POST', {
        encrypt: true,
        body: formData,
        formData: true,
      });
      if (result && result.ok) {
        toast.success('Document uploaded successfully.');
        fetchDocumentList();
        setOpen(false);
      } else {
        throw new Error('Failed to upload document.');
      }
    } catch (error) {
      toast.warning(error?.message);
    }
  };

  const handleFileDownload = (url) => {
    var link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = 'file.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="mb-8 ml-auto text-end">
                    <button className="primary-btn" onClick={() => openModal()}>
                      Add Document
                    </button>
                  </div>
                )}
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                        >
                          S.No.
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Updated At
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        ></th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.data?.map((item, index) => (
                        <tr
                          key={item?.id}
                          onClick={() => {
                            handleFileDownload(
                              `${BACKEND_URL}/documents/${item?.url}`,
                            );
                          }}
                          className="cursor-pointer"
                        >
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {index + 1}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {item?.title}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>
                              {dayjs(item?.updatedAt).format(
                                'YYYY-MM-DD @ hh:mm A',
                              )}
                            </div>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {
                              item?.url?.split('/')[
                                item?.url?.split('/')?.length - 1
                              ]
                            }
                          </td>
                          <td>
                            {allowDelete && (
                              <span
                                className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDelete(item.id);
                                }}
                              >
                                <AiFillDelete />
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CommonModal open={open} setOpen={() => setOpen(false)}>
        <form onSubmit={handleSubmit(handleDocumentAdd)}>
          <h4 className="mb-4 text-center font-bold">Add New Document</h4>

          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Title
              {errors?.title && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Title must be at least 3 letters.)
                </span>
              )}
            </label>
            <input
              {...register('title', { required: true, minLength: 3 })}
              className="w-full border p-3"
              type="text"
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">File</label>
            <input
              className="w-full border p-3"
              type="file"
              {...register('file', { required: true })}
            />
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value="Add Document"
            />
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default AdminClubDocumentsSection;
