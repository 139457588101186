/* eslint-disable react/prop-types */
import React from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';

const HeartIcon = () => (
  <AiFillHeart className="h-[24px] w-[24px] text-white" />
);

const HeartOutlineIcon = () => (
  <AiOutlineHeart className="h-[24px] w-[24px] text-white" />
);

const SingleImage = ({
  imageProps,
  handleImageFavoriteUpdate,
  handleClick,
  isAdmin = false,
}) => {
  const [isMouseOver, setIsMouseOver] = React.useState(false);

  const getFavoriteIcon = () => {
    let showHeart = true;
    if (imageProps?.item?.isFavorite) {
      if (isMouseOver) {
        showHeart = false;
      }
    } else {
      if (!isMouseOver) {
        showHeart = false;
      }
    }

    return showHeart ? <HeartIcon /> : <HeartOutlineIcon />;
  };

  return (
    <div className="relative h-auto w-full sm:h-full sm:w-auto">
      <img
        key={imageProps?.src}
        srcSet={imageProps?.srcSet}
        src={imageProps.src}
        onClick={() => {
          handleClick(imageProps?.index);
        }}
        className="max-w-auto h-[350px] w-full cursor-pointer object-cover object-top"
      />
      <div className="absolute bottom-0 left-0 w-full">
        <div className="flex flex-wrap items-center gap-1 gap-x-2 px-4 pb-4">
          {imageProps?.tags?.map((tag) => (
            <div
              key={`item-${tag.title}-${imageProps.src}`}
              className="rounded-full bg-[rgba(0,0,0,0.8)] px-2 py-0.5 text-[11px] text-yellow-400"
            >
              {tag.title}
            </div>
          ))}
        </div>
      </div>
      {isAdmin && (
        <div
          className="absolute right-0 top-0 z-[5] cursor-pointer bg-[rgba(0,0,0,0.3)] p-1"
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
          onClick={(event) => {
            event.preventDefault();
            handleImageFavoriteUpdate(imageProps);
          }}
        >
          {getFavoriteIcon()}
        </div>
      )}
    </div>
  );
};

export default React.memo(SingleImage);
