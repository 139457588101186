import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import wwbcLogo from '../assets/wwbc-logo.png';
import PasswordInput from '../components/common/password-input';
import UserContext from '../contexts/user-context/user-context';
import APIRequest from '../helpers/api-request';
import { PATHS } from '../helpers/constant';
import Image1 from '../assets/carousel-1.jpeg';
import Image2 from '../assets/carousel-2.jpeg';
import Image3 from '../assets/carousel-3.jpeg';
import Image4 from '../assets/carousel-4.jpeg';
import Image5 from '../assets/carousel-5.jpeg';

const LoginPage = () => {
  const { setUser, setToken } = useContext(UserContext);
  const [error, setError] = React.useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const result = await APIRequest('USER_LOGIN', 'POST', {
      body: {
        ...data,
        login: data.login.trim(),
      },
    });
    if (!result || !result.ok) {
      setError(result.message);
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }
    setToken(result.data?.token);
    setUser(result.data);
    if (result.data?.needsPasswordChange) {
      navigate(PATHS.UPDATE_PASSWORD);
    } else {
      navigate(PATHS.ADMIN_DASHBOARD);
    }
  };

  return (
    <div className="relative h-screen">
      <div className="p-8">
        <a href={PATHS.HOME} className="cursor-pointer">
          <img src={wwbcLogo} alt="logo" className=" h-auto w-[80px]" />
        </a>
      </div>
      <div className="w-full px-8">
        <div className=" max-w-[500px]">
          <h4 className="mb-8 text-3xl font-bold">Login</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="h-[50px]">
              {errors?.password && (
                <span className="text-[12px] text-red-600">
                  Please enter a valid password. Password should be at least 8
                  letters.
                </span>
              )}
              {errors?.login && (
                <span className="text-[12px] text-red-600">
                  Please enter valid email or phone number.
                </span>
              )}
            </div>
            <div className="mb-4">
              <label>Email/Phone</label>
              <input
                {...register('login', { required: true, minLength: 3 })}
                className="w-full border p-3"
                type="text"
              />
            </div>

            <div className="mb-0">
              <label>Password</label>

              <PasswordInput register={register} name="password" />
            </div>

            <div className="min-h-[25px]">
              <span className="text-[12px] text-red-600">{error}</span>
            </div>

            <div className="mb-4 flex items-center">
              <input
                {...register('keepMeLoggedIn')}
                type="checkbox"
                className="mr-2 h-[15px] w-[15px] cursor-pointer"
              />
              <span className="text-sm text-gray-600">Keep me logged in.</span>
            </div>

            <div className="block w-fit">
              <a href={PATHS.FORGOT_PASSWORD}>
                <div className="mb-4 cursor-pointer text-gray-400 hover:text-black">
                  Forgot password?
                </div>
              </a>
            </div>

            <div>
              <input
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
              />
            </div>
          </form>
        </div>
      </div>
      <div className="absolute right-0 bottom-0 hidden max-h-screen w-full  overflow-y-auto md:top-0 md:block md:w-1/2">
        <img src={Image1} alt="logo" className="h-auto w-full" />
        <img src={Image2} alt="logo" className="h-auto w-full" />
        <img src={Image3} alt="logo" className="h-auto w-full" />
        <img src={Image4} alt="logo" className="h-auto w-full" />
        <img src={Image5} alt="logo" className="h-auto w-full" />
      </div>
    </div>
  );
};

export default LoginPage;
