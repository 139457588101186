/* eslint-disable quotes */

import {
  AiFillDelete,
  AiFillEdit,
  AiOutlineDown,
  AiOutlineUp,
} from 'react-icons/ai';
import { PERMISSON_TYPES, USER_ROLES } from '../../helpers/constant';
import { hasPermission, randomPassword } from '../../helpers/functions';

import APIRequest from '../../helpers/api-request';
import { CSVLink } from 'react-csv';
import CommonModal from '../common/modal';
import PasswordInput from '../common/password-input';
import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../common/spinner';
import UserPermissionMatrix from './users/permission-matrix';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const CSV_HEADERS = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phone' },
  { label: 'Role', key: 'role' },
];

const CSV_DATA = [
  {
    firstName: 'John',
    lastName: 'Doe',
    email: 'test@gmail.com',
    phone: '123123123',
    role: 'Social Member',
  },
  {
    firstName: 'John2',
    lastName: 'Doe2',
    email: 'test1@gmail.com',
    phone: '1111111111',
    role: 'Full Member',
  },
  {
    firstName: 'John3',
    lastName: 'Doe3',
    email: 'test2@gmail.com',
    phone: '222222222',
    role: 'Committee',
  },
];

const AdminUsersSection = ({ permissions = [] }) => {
  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [userPermissions, setUserPermissions] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [passwordFormOpen, setPasswordFormOpen] = React.useState(false);
  const [userUploadOpen, setUserUploadOpen] = React.useState(false);
  const [file, setFile] = React.useState();
  const [multipleUserError, setMultipleUserError] = React.useState({
    message: '',
    items: [],
  });

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.MEMBERS_UPDATE, permissions),
  );
  const [allowDelete, setAllowDelete] = React.useState(
    hasPermission(PERMISSON_TYPES.MEMBERS_DELETE, permissions),
  );
  const [allowCreate, setAllowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.MEMBERS_CREATE, permissions),
  );
  const [passwordError, setPasswordError] = React.useState('');
  const [filters, setFilters] = React.useState({
    search: '',
    role: '',
    sort: 'asc',
  });

  React.useEffect(() => {
    setAllowUpdate(hasPermission(PERMISSON_TYPES.MEMBERS_UPDATE, permissions));
    setAllowDelete(hasPermission(PERMISSON_TYPES.MEMBERS_DELETE, permissions));
    setAllowCreate(hasPermission(PERMISSON_TYPES.MEMBERS_CREATE, permissions));
    if (permissions?.length > 0) {
      fetchUsersList();
    }
  }, [permissions]);

  React.useEffect(() => {
    if (!userUploadOpen) {
      setMultipleUserError({
        message: '',
        items: [],
      });
    }
  }, [userUploadOpen]);

  React.useEffect(() => {
    let filteredUsers = state?.data;
    if (filters?.search?.length > 0) {
      filteredUsers = filteredUsers.filter((user) => {
        if (
          user?.email?.toLowerCase().includes(filters.search?.toLowerCase()) ||
          user?.email
            ?.toLowerCase()
            .includes(filters.firstName?.toLowerCase()) ||
          user?.email?.toLowerCase().includes(filters.lastName?.toLowerCase())
        ) {
          return true;
        }

        return false;
      });
    }

    if (filters.role?.length > 0) {
      filteredUsers = filteredUsers.filter((user) => {
        if (
          filters.role === 'Admin' &&
          ['Admin', 'ProductOwner'].includes(user.role)
        ) {
          return true;
        }
        if (filters.role === user.role) {
          return true;
        }

        return false;
      });
    }
    filteredUsers = filteredUsers.sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`,
      ),
    );
    if (filters.sort === 'desc') {
      filteredUsers = filteredUsers.reverse();
    }
    setState((previous) => ({ ...previous, dataToShow: filteredUsers }));
  }, [filters]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
  } = useForm();

  const {
    register: passwordRegister,
    handleSubmit: passwordHandleSubmit,
    reset: passwordReset,
    formState: { errors: passwordErrors },
    setValue: setPasswordValue,
  } = useForm();

  const fetchUsersList = async () => {
    const result = await APIRequest('USERS', 'GET', { encrypt: true });
    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data,
        dataToShow: result.data.sort((a, b) =>
          `${a.firstName} ${a.lastName}`.localeCompare(
            `${b.firstName} ${b.lastName}`,
          ),
        ),
      });
    } else {
      setState({
        name: 'invalid',
        message: result.message,
      });
    }
  };

  const handleUserCall = async (data) => {
    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }

    if (!isEditMode) {
      if (data.password !== data?.confirmPassword) {
        toast.warning('Password do not match.');
        return;
      }
      if (data?.password?.length < 8) {
        toast.warning('Password should be at least 8 characters.');
        return;
      }
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(data?.password?.trim())) {
        toast.warning(
          'Password should have at least one uppercase, one lowercase and a number.',
        );
        return;
      }
      delete data?.confirmPassword;
    }

    if (data.email?.length <= 0) {
      delete data.email;
    }
    let result = isEditMode
      ? await APIRequest('USERS', 'PUT', {
          encrypt: true,
          addToUrl: `/${data.id}`,
          body: {
            ...data,
            handicap: 0,
            permissions: userPermissions,
          },
        })
      : await APIRequest('USER_REGISTER', 'POST', {
          encrypt: true,
          body: {
            ...data,
            handicap: 0,
            permissions: userPermissions,
          },
        });

    if (result && result.ok) {
      toast.success('Added new user successfully');
      reset();
      setOpen(false);
      fetchUsersList();
    } else {
      toast.warning('Something went wrong.');
    }
  };

  const handleDelete = async (id) => {
    if (allowDelete) {
      if (window.confirm('Are you sure you want to delete the user?')) {
        const result = await APIRequest('USERS', 'DELETE', {
          encrypt: true,
          addToUrl: `/${id}`,
        });
        if (result && result.ok) {
          toast.success('Deleted user successfully');
          fetchUsersList();
        } else {
          toast.warning('Something went wrong.');
        }
      }
    }
  };

  const openModal = (data, isEdit = false) => {
    reset({
      ...data,
    });

    if (isEdit) {
      fetchUserPermission(data.id);
    } else {
      setUserPermissions([]);
      setIsEditMode(false);
      setOpen(true);
      setValue('role', 'SocialMember');
    }
  };

  const openPasswordModal = (data) => {
    passwordReset({ id: data.id });
    setPasswordFormOpen(true);
  };

  const fetchUserPermission = async (userId) => {
    setIsLoading(true);
    setOpen(true);
    setIsEditMode(true);
    const result = await APIRequest('USERS', 'GET', {
      encrypt: true,
      addToUrl: `/${userId}`,
    });
    if (result.ok) {
      setUserPermissions(result.data.permissions);
      setIsLoading(false);
    } else {
      toast.warning('Something went wrong.');
    }
  };

  const handlePasswordReset = async (data) => {
    if (data.password !== data?.confirmPassword) {
      toast.warning('Password do not match.');
      return;
    }
    if (data?.password?.length < 8) {
      toast.warning('Password should be at least 8 characters.');
      return;
    }

    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(data?.password?.trim())) {
      toast.warning(
        'Password should have at least one uppercase, one lowercase and a number.',
      );
      return;
    }

    delete data?.confirmPassword;

    let result = await APIRequest('USERS', 'PUT', {
      encrypt: true,
      addToUrl: `/${data.id}`,
      body: {
        ...data,
      },
    });

    if (result && result.ok) {
      toast.success('Password updated successfully.');
      passwordReset();
      setPasswordFormOpen(false);
      fetchUsersList();
    } else {
      toast.warning('Something went wrong.');
      setPasswordError(
        'Please confirm your password has at least one uppercase letter and one lowercase letter.',
      );
      setTimeout(() => {
        setPasswordError('');
      }, 6000);
    }
  };

  const handleCSVUpload = async (event) => {
    event.preventDefault();

    if (file) {
      const fileReader = new FileReader();

      fileReader.addEventListener('load', async function (event) {
        const text = event.target.result;
        const csvRows = text.slice(text.indexOf('\n') + 1).split('\n');

        let index = 0;
        let out = [];
        for (const rowItem of csvRows) {
          const values = rowItem
            .split(',')
            .map((item) =>
              item.replaceAll(`"`, '').replaceAll(`'`, '').replaceAll('\r', ''),
            );

          index += 1;

          if (index === 0) {
            continue;
          }
          let role = USER_ROLES.find(
            (item) => item.label?.toLowerCase() === values[4]?.toLowerCase(),
          );
          if (!role) {
            role = USER_ROLES.find((item) => item.value === 'SocialMember');
          }

          out.push({
            firstName: values[0]?.trim(),
            lastName: values[1]?.trim(),
            email: values[2]?.trim(),
            phone: values[3]?.trim()?.length <= 0 ? null : values[3]?.trim(),
            password: randomPassword(),
            role: role.value,
            permissions: role.permissions,
          });
        }
        console.log(out);
        await handleMultipleUserUpload(out);
      });

      fileReader.readAsText(file);
    } else {
      toast.warning('Please select a csv file');
    }
  };

  const handleMultipleUserUpload = async (userList) => {
    setMultipleUserError({
      message: '',
      items: [],
    });
    let result = await APIRequest('USER_REGISTER_MANY', 'POST', {
      encrypt: true,
      body: {
        users: userList,
      },
    });

    if (result && result.ok) {
      toast.success('Multiple user added');
      passwordReset();
      setPasswordFormOpen(false);
      fetchUsersList();
      setUserUploadOpen(false);
    } else {
      setMultipleUserError({
        message: result.message,
        items: result?.data || [],
      });
      toast.warning('Something went wrong.');
    }
  };

  const getRoleLabel = (userRole) => {
    const role = USER_ROLES.find((item2) => item2.value === userRole)
      ? USER_ROLES.find((item2) => item2.value === userRole)?.label
      : 'Full Member';
    return role === 'Admin' ? 'Full Member' : role;
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="flex flex-row justify-start md:justify-end">
                    <div className="mb-8 mr-4">
                      <button
                        className="primary-btn"
                        onClick={() => setUserUploadOpen(true)}
                      >
                        Upload User
                      </button>
                    </div>
                    <div className="mb-8 mr-4">
                      <button
                        className="primary-btn"
                        onClick={() => openModal({}, false)}
                      >
                        Add User
                      </button>
                    </div>
                  </div>
                )}

                <div className="flex flex-col justify-start md:flex-row">
                  <div className="mb-8 mr-4">
                    <input
                      value={filters.search}
                      onChange={(event) =>
                        setFilters({ ...filters, search: event.target.value })
                      }
                      className="w-[250px] border p-3"
                      placeholder="Email or Name"
                    />
                  </div>
                  <div className="mb-8 mr-4 ">
                    <select
                      name="role"
                      className="w-[250px] border p-3"
                      onChange={(event) => {
                        setFilters({ ...filters, role: event.target.value });
                      }}
                      defaultValue={''}
                      placeholder="Role"
                    >
                      <option value="">Select role</option>
                      {USER_ROLES.map((role) => (
                        <option key={role.label} value={role.value}>
                          {role.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="max-w-[1200px] overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="w-full min-w-full table-auto divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Full Name
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              setFilters((previous) => ({
                                ...previous,
                                sort: previous.sort === 'asc' ? 'desc' : 'asc',
                              }))
                            }
                          >
                            {filters.sort === 'asc' ? (
                              <AiOutlineDown className="inline" />
                            ) : (
                              <AiOutlineUp className="inline" />
                            )}
                          </span>
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Contact
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.dataToShow?.map((item) => (
                        <tr key={item?.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 ">
                            {item?.firstName} {item?.lastName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 ">
                            {item?.email}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 ">
                            {item?.phone}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 ">
                            {getRoleLabel(item?.role)}
                          </td>
                          <td>
                            {item?.role === 'ProductOwner' ? (
                              <></>
                            ) : (
                              <>
                                {allowUpdate && (
                                  <span
                                    className="mx-2 cursor-pointer text-primary hover:text-cyan-700"
                                    onClick={() => openPasswordModal(item)}
                                  >
                                    Reset Password
                                  </span>
                                )}
                              </>
                            )}
                          </td>
                          <td>
                            {item?.role === 'ProductOwner' ? (
                              <></>
                            ) : (
                              <div className="flex items-center self-center">
                                {allowUpdate && (
                                  <>
                                    <span
                                      className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                      onClick={() => openModal(item, true)}
                                    >
                                      <AiFillEdit />
                                    </span>
                                  </>
                                )}
                                {allowDelete && (
                                  <span
                                    className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                    onClick={() => handleDelete(item.id)}
                                  >
                                    <AiFillDelete />
                                  </span>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <CommonModal
        open={open}
        setOpen={() => setOpen(false)}
        modalClasses="min-w-full md:min-w-[800px]"
      >
        {isLoading ? (
          <div className="my-8 text-center">
            <Spinner />
            <p>Fetching User Information</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit(handleUserCall)}>
            <h4 className="mb-4 text-center font-bold">
              {isEditMode ? 'Edit user' : 'Add user'}
            </h4>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-x-8">
              <div className="mb-2">
                <label className="text-sm text-gray-500">First Name</label>
                <input
                  {...register('firstName')}
                  className="w-full border p-3"
                />
              </div>
              <div className="mb-2">
                <label className="text-sm text-gray-500">Last Name</label>
                <input
                  {...register('lastName')}
                  className="w-full border p-3"
                />
              </div>
              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Email
                  {errors?.email && (
                    <span className="pl-2 text-[12px] text-red-600">
                      (Please enter a valid Email address.)
                    </span>
                  )}
                </label>
                <input
                  {...register('email')}
                  className="w-full border p-3"
                  type="email"
                  autoComplete="off"
                />
              </div>
              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Phone
                  {errors?.phone && (
                    <span className="pl-2 text-[12px] text-red-600">
                      (Enter valid phone number.)
                    </span>
                  )}
                </label>
                <input
                  {...register('phone')}
                  className="w-full border p-3"
                  type="tel"
                />
              </div>

              {!isEditMode && (
                <>
                  <div className="mb-2">
                    <label className="text-sm text-gray-500">
                      Password
                      {errors?.password && (
                        <span className="pl-2 text-[12px] text-red-600">
                          (Password must be at least 8 letters.)
                        </span>
                      )}
                    </label>

                    <PasswordInput register={register} name="password" />
                    <div
                      onClick={() => {
                        const password = randomPassword();
                        setValue('password', password);
                        setValue('confirmPassword', password);
                      }}
                      className="cursor-pointer text-end text-[12px] underline"
                    >
                      Generate Random Password
                    </div>
                  </div>
                  <div className="mb-2">
                    <label className="text-sm text-gray-500">
                      Confirm Password
                      {errors?.confirmPassword && (
                        <span className="pl-2 text-[12px] text-red-600">
                          (Password must be at least 8 letters.)
                        </span>
                      )}
                    </label>

                    <PasswordInput register={register} name="confirmPassword" />
                  </div>
                </>
              )}
              <div className="mb-2">
                <label className="text-sm text-gray-500">
                  Role
                  {errors?.role && (
                    <span className="pl-2 text-[12px] text-red-600">
                      (Please select a role.)
                    </span>
                  )}
                </label>

                <select
                  name="role"
                  className="w-full border p-3"
                  onChange={(event) => {
                    setValue('role', event.target.value);
                    const userRole = USER_ROLES.find(
                      (item) => item.value === event.target.value,
                    );
                    setUserPermissions(userRole.permissions);
                  }}
                  defaultValue={getValues('role') || USER_ROLES[3]?.value}
                >
                  <option></option>
                  {USER_ROLES.map((role) => (
                    <option key={role.label} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="md:text-md col-span-1 text-xs md:col-span-2">
                <UserPermissionMatrix
                  userPermissions={userPermissions}
                  setUserPermissions={setUserPermissions}
                />
              </div>
              <div className="col-span-1 md:col-span-2">
                <div className="mt-4 text-center">
                  <input
                    type="submit"
                    className="primary-btn cursor-pointer p-2 px-8 "
                    value={isEditMode ? 'Save User' : 'Add User'}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </CommonModal>

      <CommonModal
        open={passwordFormOpen}
        setOpen={() => setPasswordFormOpen(false)}
        modalClasses="min-w-full md:min-w-[400px]"
      >
        <h4 className="mb-4 text-center font-bold">Reset Password</h4>
        <form onSubmit={passwordHandleSubmit(handlePasswordReset)}>
          <div
            onClick={() => {
              const password = randomPassword();
              setPasswordValue('password', password);
              setPasswordValue('confirmPassword', password);
            }}
            className=" mb-2 cursor-pointer text-end text-[12px] underline"
          >
            Generate Random Password
          </div>
          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Password
              {passwordErrors?.password && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Password must be at least 8 letters.)
                </span>
              )}
            </label>

            <PasswordInput register={passwordRegister} name="password" />
          </div>
          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Confirm Password
              {passwordErrors?.confirmPassword && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Password must be at least 8 letters.)
                </span>
              )}
            </label>

            <PasswordInput register={passwordRegister} name="confirmPassword" />
          </div>
          {passwordError?.length > 0 && (
            <span className="text-[13px] text-red-600">{passwordError}</span>
          )}
          <div className="col-span-2">
            <div className="mt-4 text-center">
              <input
                type="submit"
                className="primary-btn cursor-pointer p-2 px-8 "
                value="Save Password"
              />
            </div>
          </div>
        </form>
      </CommonModal>

      <CommonModal
        open={userUploadOpen}
        setOpen={() => setUserUploadOpen(false)}
        modalClasses="min-w-full md:min-w-[400px]"
      >
        <h4 className="mb-4 text-center font-bold">User File Upload</h4>
        <CSVLink
          data={CSV_DATA}
          headers={CSV_HEADERS}
          filename="user-data.csv"
          className="ml-auto block w-fit"
        >
          <div className="text-primary underline">Download sample file</div>
        </CSVLink>
        <form onSubmit={handleCSVUpload}>
          <div className="my-6">
            <input
              type="file"
              accept=".csv"
              className="w-fit-content "
              onChange={(event) => setFile(event.target.files[0])}
            />
          </div>
          {multipleUserError?.message?.length > 0 && (
            <>
              <div className="text-red-600">{multipleUserError.message}</div>
              <div className="flex flex-wrap text-[13px] text-red-600">
                {multipleUserError?.items?.slice(0, 10)?.join(', ')}
              </div>
            </>
          )}
          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value="Save"
              disabled={!file}
            />
          </div>
        </form>
      </CommonModal>
    </>
  );
};

AdminUsersSection.propTypes = {
  permissions: PropTypes.array,
};

export default AdminUsersSection;
