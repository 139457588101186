import React, { useState } from 'react';

import APIRequest from '../../helpers/api-request';
import { MESSAGE_OPTIONS } from '../../helpers/constant';
import { toast } from 'react-toastify';

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    subject: MESSAGE_OPTIONS[0],
    message: '',
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleValueChange = (event) => {
    setFormValues((previous) => ({
      ...previous,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let result = await APIRequest('CONTACT', 'POST', {
      encrypt: true,
      body: formValues,
    });

    if (result && result.ok) {
      toast.success(
        'We have recieved your message. We will contact you as soon as we can.',
      );

      setIsLoading(false);
      setFormValues({
        name: '',
        email: '',
        phone: '',
        subject: MESSAGE_OPTIONS[0],
        message: '',
      });
    } else {
      setIsLoading(false);
      toast.warning('Failed to register your enquiry.');
    }
  };

  return (
    <div>
      <div className="mb-6">
        <div className="mb-2 font-bold text-gray-600">Name</div>
        <input
          className="w-full p-3 border"
          type="text"
          name="name"
          onChange={handleValueChange}
          value={formValues?.name}
          required
        />
      </div>
      <div className="mb-6">
        <div className="mb-2 font-bold text-gray-600">Email</div>
        <input
          className="w-full p-3 border"
          type="email"
          name="email"
          value={formValues?.email}
          onChange={handleValueChange}
          required
        />
      </div>
      <div className="mb-6">
        <div className="mb-2 font-bold text-gray-600">Phone</div>
        <input
          className="w-full p-3 border"
          type="tel"
          name="phone"
          value={formValues?.phone}
          onChange={handleValueChange}
        />
      </div>
      <div className="mb-6">
        <div className="mb-2 font-bold text-gray-600">Subject</div>
        <div className="flex flex-row ">
          {MESSAGE_OPTIONS.map((message) => (
            <div
              key={message}
              className={`w-1/3 cursor-pointer border p-2 text-center hover:bg-[#361746] hover:text-white ${
                formValues.subject === message ? 'bg-primary text-white' : ''
              }`}
              onClick={() =>
                setFormValues((previous) => ({
                  ...previous,
                  subject: message,
                }))
              }
            >
              {message}
            </div>
          ))}
        </div>
      </div>
      <div className="mb-6">
        <div className="mb-2 font-bold text-gray-600">Message</div>
        <textarea
          name="message"
          onChange={handleValueChange}
          rows="5"
          className="w-full p-3 mb-2 border resize-none"
          value={formValues?.message}
        ></textarea>
      </div>
      <div>
        <button
          onClick={handleFormSubmit}
          className={`primary-btn ${isLoading ? 'opacity-50' : ''} w-[250px]`}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send Message'}
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
