import { AiFillDelete, AiFillEdit } from 'react-icons/ai';

import APIRequest from '../../helpers/api-request';
import CommonModal from '../common/modal';
import { PERMISSON_TYPES } from '../../helpers/constant';
import React from 'react';
import Spinner from '../common/spinner';
import UserContext from '../../contexts/user-context/user-context';
import { hasPermission } from '../../helpers/functions';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const AdminGameTypesSection = () => {
  const { permissions = [] } = React.useContext(UserContext);

  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [allowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.GAME_TYPES_UPDATE, permissions),
  );
  const [allowDelete] = React.useState(
    hasPermission(PERMISSON_TYPES.GAME_TYPES_DELETE, permissions),
  );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.GAME_TYPES_CREATE, permissions),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  React.useEffect(() => {
    fetchEventsList();
  }, []);

  const fetchEventsList = async () => {
    const result = await APIRequest('EVENTS', 'GET', { encrypt: true });
    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data,
      });
    } else {
      setState({
        name: 'invalid',
        message: result.message,
      });
    }
  };

  const handleEventCall = async (data) => {
    if (data.address?.latLong?.length <= 0) {
      data.address.latLong = null;
    }

    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }
    let result = isEditMode
      ? await APIRequest('EVENTS', 'PUT', {
          encrypt: true,
          addToUrl: `/${data.id}`,
          body: {
            ...data,
          },
        })
      : await APIRequest('EVENTS', 'POST', {
          encrypt: true,
          body: {
            ...data,
          },
        });

    if (result && result.ok) {
      toast.success('Added new event successfully');
      reset();
      setOpen(false);
      fetchEventsList();
    } else {
      toast.warning('Failed to add new event');
    }
  };

  const handleDelete = async (id) => {
    if (allowDelete) {
      if (window.confirm('Are you sure you want to delete the event?')) {
        const result = await APIRequest('EVENTS', 'DELETE', {
          encrypt: true,
          addToUrl: `/${id}`,
        });
        if (result && result.ok) {
          toast.success('Deleted event successfully');
          fetchEventsList();
        } else {
          toast.warning('Failed to delete event');
        }
      }
    }
  };

  const openModal = (data, isEdit = false) => {
    reset({
      ...data,
    });
    setIsEditMode(isEdit);
    setOpen(true);
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="mb-8 ml-auto text-end">
                    <button
                      className="primary-btn"
                      onClick={() => openModal({}, false)}
                    >
                      Add Event
                    </button>
                  </div>
                )}
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Total Fixtures
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.data?.map((item) => (
                        <tr key={item?.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {item?.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {item?.fixtures?.length ?? 0}
                          </td>

                          <td>
                            <div className="flex items-center self-center">
                              {allowUpdate && (
                                <span
                                  className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                  onClick={() => openModal(item, true)}
                                >
                                  <AiFillEdit />
                                </span>
                              )}
                              {allowDelete && (
                                <span
                                  className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <AiFillDelete />
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CommonModal open={open} setOpen={() => setOpen(false)}>
        <form onSubmit={handleSubmit(handleEventCall)}>
          <h4 className="mb-4 text-center font-bold">
            {isEditMode ? 'Edit event' : 'Add a new event'}
          </h4>
          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Event Name
              {errors?.name && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Event Name must be at least 3 letters.)
                </span>
              )}
            </label>
            <input
              {...register('name', { required: true, minLength: 3 })}
              className="w-full border p-3"
              type="text"
            />
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value={isEditMode ? 'Save Event' : 'Add Event'}
            />
          </div>
        </form>
      </CommonModal>
    </>
  );
};

export default AdminGameTypesSection;
