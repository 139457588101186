import React from 'react';
import PropTypes from 'prop-types';
import MainLayout from '../../layouts/main';

const GeneralRoute = ({ children }) => {
  return <MainLayout>{children}</MainLayout>;
};

GeneralRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GeneralRoute;
