import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '../common/accordion';
import TitleDivider from '../common/title-divider';

const NewsSection = ({ title, news, allowUpdate = false, setModalState }) => {
  const [newsState, setNewsState] = React.useState({
    data: [],
    dataToShow: [],
    end: 5,
    step: 5,
  });

  React.useEffect(() => {
    if (title && news) {
      setNewsState((previous) => ({
        ...previous,
        name: 'results',
        data: news,
        dataToShow: news?.slice(0, previous.end),
        end: previous.end + previous.step,
      }));
    }
  }, [news, title]);

  const loadMoreNews = () => {
    setNewsState((previous) => ({
      ...previous,
      dataToShow: previous?.data?.slice(0, previous.end),
      end: previous.end + previous.step,
    }));
  };

  return (
    <div className="mb-16">
      <TitleDivider title={title} headerClasses="text-gray-700" />
      <div className="grid grid-cols-1 md:grid-cols-2">
        {newsState?.dataToShow?.map((news, index) => (
          <Accordion
            data={news}
            key={`NEWS-${index}`}
            isOpen={index === 0}
            allowUpdate={allowUpdate}
            setModalState={setModalState}
          />
        ))}
      </div>
      {newsState?.dataToShow?.length < newsState?.data?.length && (
        <button className="primary-btn mb-8" onClick={loadMoreNews}>
          Show More
        </button>
      )}
    </div>
  );
};

NewsSection.propTypes = {
  title: PropTypes.string,
  news: PropTypes.arrayOf(
    PropTypes.shape({
      subject: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      publishedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  allowUpdate: PropTypes.bool,
  setModalState: PropTypes.func,
};

export default NewsSection;
