import DOMPurify from 'dompurify';
import { BACKEND_URL } from './constant';

export const hasPermission = (action, permissions = []) => {
  if (
    permissions.includes('product_owner') ||
    permissions.includes('app_admin') ||
    permissions.includes(action)
  ) {
    return true;
  }
  return false;
};

export const createMarkup = (html) => {
  return {
    __html: DOMPurify.sanitize(html),
  };
};

export const randomText = (length = 7) => {
  return (Math.random() + 1).toString(36).substring(12 - length);
};

export const randomPassword = () => {
  return (
    randomText(4).toUpperCase() +
    randomText(4) +
    Math.floor(Math.random() * 100)
  );
};

export const clamp = (value, minValue, maxValue) => {
  return Math.min(maxValue, Math.max(value, minValue));
};

export const getFormattedText = (description) => {
  // TO HANDLE OLD STRING DATA
  if (typeof description !== 'string') {
    return '';
  } else {
    return description;
  }
};

export const parseImageURL = (url) => {
  return url?.startsWith('https://') ? url : BACKEND_URL + '/images/' + url;
};
